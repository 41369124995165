import React from "react";
import styled from "styled-components";
import Button from "../../Button/Button";
import Text from "../../Text/Text";
import LinkExternal from "../../Link/LinkExternal";
import Flex from "../../Box/Flex";
import { Modal } from "../ModalUI";
import CopyToClipboard from "./CopyToClipboard";
import { connectorLocalStorageKey } from "./config";
import { registerToken } from "../../../utils/wallet";
import { USDT_TOKEN, CLOUD_TOKEN } from "src/config";

interface Props {
  account: string;
  logout: () => void;
  onDismiss?: () => void;
}

const User = styled.div`
  display: inline-flex;
  width: 250px;
`;

const AccountModal: React.FC<Props> = ({
  account,
  logout,
  onDismiss = () => null,
}) => (
  <Modal title="Your wallet" onDismiss={onDismiss}>
    <Text
      fontSize="20px"
      bold
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "8px",
      }}
    >
      {account}
    </Text>
    <Flex mb="32px">
      <LinkExternal
        small
        href={`https://bscscan.com/address/${account}`}
        style={{ marginRight: "16px" }}
      >
        View on BscScan
      </LinkExternal>
      <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
    </Flex>
    <Flex justifyContent="center" mb="10px">
      <Button
        scale="sm"
        variant="secondary"
        onClick={() =>
          registerToken(
            CLOUD_TOKEN,
            "CLOUD",
            18,
            "https://app.nftcloud.net/token.png"
          )
        }
      >
        Add Cloud Token
      </Button>
    </Flex>
    <Flex justifyContent="center" mb="10px">
      <Button
        scale="sm"
        variant="secondary"
        onClick={() =>
          registerToken(
            USDT_TOKEN,
            "USDT",
            18,
            "https://demo.nftcloud.net/0x55d398326f99059fF775485246999027B3197955.svg"
          )
        }
      >
        Add USDT Token
      </Button>
    </Flex>
    <hr />
    <Flex justifyContent="center" mt="10px">
      <Button
        scale="sm"
        variant="secondary"
        onClick={() => {
          logout();
          window.localStorage.removeItem(connectorLocalStorageKey);
          onDismiss();
        }}
      >
        Logout
      </Button>
    </Flex>
  </Modal>
);

export default AccountModal;
