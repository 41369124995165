/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Icon,
  Stack,
  InputGroup,
  Input,
  Button,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import Cookies from "universal-cookie";
import useReferrer from "../../../hooks/useReferer";
import heroImage from "src/assets/hero.png";
import styled from "styled-components";

import useAuth from "../../../hooks/useAuth";
import { useWalletModal } from "../../../components/common/WalletModal";
import useRecordReferral from "../../../hooks/useRecordReferral";
import useTokenBalance from "src/hooks/useTokenBalance";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import { CLOUD_TOKEN, USDT_TOKEN } from "src/config";
import { useFetchStakingInfo } from "src/hooks/useFetchStakingInfo";
import { useGetTotalReward } from "src/hooks/useGetTotalReward";

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) =>
    isTooltipDisplayed ? "block" : "none"};
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #191326;
  color: #ffffff;
  border-radius: 16px;
  opacity: 0.7;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 8px;
`;

function TokenIcon() {
  return <img src="/token.png" width="16px" height="16px" />;
}

function Referral(props: any) {
  const { showUnlockButton, owner } = props;
  const { account } = useWeb3React();
  const [ref, setRef] = useState(useReferrer().ref);
  const cookies = new Cookies();

  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(
    login,
    logout,
    account as any
  );

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const referralLink = `https://app.nftcloud.net/my-network?team=${account}`;

  const copyToClipboardWithCommand = (content: string) => {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  function displayTooltip() {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1000);
  }

  const tokenBalance = useTokenBalance(CLOUD_TOKEN).balance;
  const yourReward = useGetTotalReward();

  const { onJoin } = useRecordReferral();

  return (
    <Box
      w="full"
      padding={["20px", "20px", "30px"]}
      background="linear-gradient(180deg, #0054D1 0%, #00D1FF 100%)"
      border="3px solid #FFFFFF"
      backdropFilter=" blur(40px)"
      borderRadius="40px"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Text
        fontFamily="Clash Display, sans-serif"
        color="white"
        fontSize="40px"
        fontWeight={600}
        lineHeight="49px"
        mb={["20px", "20px", "60px"]}
        align={["center", "center", "left"]}
      >
        Referral
      </Text>
      <Image src={heroImage} mb="20px" display={["block", "block", "none"]} />
      <Image
        src={heroImage}
        display={["none", "none", "block"]}
        position="absolute"
        width="40%"
        right="0"
        bottom="0"
      />
      <VStack w={["100%", "100%", "300px"]} mb={["20px", "20px", "60px"]}>
        <HStack
          background="linear-gradient(0deg, rgba(255, 181, 36, 0.4), rgba(255, 181, 36, 0.4)), #FFFFFF"
          borderRadius="12px"
          p="16px"
          w="full"
          justifyContent="space-between"
        >
          <Text
            fontFamily="Poppins, sans-serif"
            color="#181F51"
            fontSize={["14px", "14px", "16px"]}
            fontWeight={600}
            lineHeight="21px"
          >
            Token Balance
          </Text>
          <HStack>
            <Text
              fontFamily="Poppins, sans-serif"
              color="#181F51"
              fontSize={["24px", "24px", "28px"]}
              fontWeight={600}
              lineHeight="21px"
            >
              {getFullDisplayBalance(tokenBalance, 18, 4)}
            </Text>
            <TokenIcon />
          </HStack>
        </HStack>
        <HStack
          background="linear-gradient(0deg, rgba(255, 181, 36, 0.4), rgba(255, 181, 36, 0.4)), #FFFFFF"
          borderRadius="12px"
          p="16px"
          w="full"
          justifyContent="space-between"
        >
          <Text
            fontFamily="Poppins, sans-serif"
            color="#181F51"
            fontSize={["14px", "14px", "16px"]}
            fontWeight={600}
            lineHeight="21px"
          >
            Your Reward
          </Text>
          <HStack>
            <Text
              fontFamily="Poppins, sans-serif"
              color="#181F51"
              fontSize={["24px", "24px", "28px"]}
              fontWeight={600}
              lineHeight="21px"
            >
              {getFullDisplayBalance(yourReward, 18, 4)}
            </Text>
            <TokenIcon />
          </HStack>
        </HStack>
      </VStack>

      {showUnlockButton ? (
        <Flex gap="4">
          <Row>
            <Button
              onClick={onPresentConnectModal}
              fontSize="18px"
              {...activeButtonStyle}
              style={{ width: "150px" }}
            >
              Unlock Wallet
            </Button>
          </Row>
          <Row>
            <Text fontSize="14px">
              Unlock wallet to get your unique referral link
            </Text>
          </Row>
        </Flex>
      ) : (
        <Stack
          w={["100%", "100%", "50%"]}
          direction={["column", "column", "row"]}
          gap="20px"
        >
          {owner === "0x0000000000000000000000000000000000000000" && (
            <VStack alignItems="flex-start">
              <Text
                fontFamily="Poppins, sans-serif"
                color="#FFFFFF"
                fontSize={["14px", "14px", "16px"]}
                fontWeight={600}
                lineHeight="21px"
              >
                Join a team
              </Text>
              <HStack
                w="full"
                borderRadius="12px"
                background="white"
                padding="8px"
              >
                <InputGroup>
                  <Input
                    border="none"
                    placeholder="Enter an address"
                    value={
                      Web3.utils.isAddress(ref)
                        ? `${ref.slice(0, 12)}...${ref.slice(-12)}`
                        : ref
                    }
                    onChange={(event) => {
                      cookies.set(
                        "referal_code",
                        event.target.value
                          .replace("https://app.nftcloud.net/?team=", "")
                          .replace(
                            "https://app.nftcloud.net/my-network?team=",
                            ""
                          ),
                        { path: "/" }
                      );
                      setRef(event.target.value);
                    }}
                    disabled={
                      ref !== "0x0000000000000000000000000000000000000000" &&
                      Web3.utils.isAddress(ref)
                    }
                  />
                </InputGroup>
                <Button {...activeButtonStyle} onClick={onJoin}>
                  Join
                </Button>
              </HStack>
            </VStack>
          )}
          {owner !== "0x0000000000000000000000000000000000000000" && (
            <VStack alignItems="flex-start">
              <Text
                fontFamily="Poppins, sans-serif"
                color="#FFFFFF"
                fontSize={["14px", "14px", "16px"]}
                fontWeight={600}
                lineHeight="21px"
              >
                Your Team
              </Text>
              <HStack
                w="full"
                borderRadius="12px"
                background="white"
                padding="8px"
              >
                <InputGroup>
                  <Input border="none" value={owner} disabled />
                </InputGroup>
              </HStack>
            </VStack>
          )}
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Poppins, sans-serif"
              color="#FFFFFF"
              fontSize={["14px", "14px", "16px"]}
              fontWeight={600}
              lineHeight="21px"
            >
              Your Referral Link
            </Text>
            <HStack
              w="full"
              borderRadius="12px"
              background="white"
              padding="8px"
            >
              <InputGroup>
                <Input
                  _disabled={{ color: "#181F51" }}
                  disabled
                  fontWeight={600}
                  border="none"
                  value={referralLink}
                />
              </InputGroup>
              <Button
                {...activeButtonStyle}
                onClick={() => {
                  copyToClipboardWithCommand(referralLink);
                  displayTooltip();
                }}
              >
                Copy
                <Tooltip isTooltipDisplayed={isTooltipDisplayed}>
                  Copied
                </Tooltip>
              </Button>
            </HStack>
          </VStack>
        </Stack>
      )}
    </Box>
  );
}

export default Referral;
