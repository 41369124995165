import { useEffect, useState } from "react";
import axios, { AxiosError, Method } from "axios";

axios.defaults.baseURL = "https://nfts.nftcloud.net";

export const getNftInfo = async (tokenId) => {
  try {
    const response = await axios({
      url: `/nft-info/${tokenId}`,
      method: "GET",
    });
    const data = response?.data;
    return data;
  } catch (err) {
    return {
      name: "",
      image: "",
    };
  }
};
