import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import ImageDetails from "./ImageDetails";

interface INFTDetailsModal {
  isOpen: boolean;
  onClose: () => void;
  item: any;
}

function ImageDetailsModal(props: INFTDetailsModal) {
  const { isOpen, onClose, item } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width="1140px"
        maxWidth="none"
        borderRadius="20px"
        overflow="hidden"
      >
        <ModalBody width="100%" padding="0" height="100%">
          <ImageDetails
            containerProps={{
              width: "100%",
              background:
                "linear-gradient(180deg, #FF6F80 0%, #FFDB6A 59.37%, #FFCE4A 76.56%, #FFC133 87.5%, #FFA800 100%, #FFB320 100%)",
            }}
            isVideo={item.collection === "V.I.P"}
            tokenURI={item.tokenURI}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ImageDetailsModal;
