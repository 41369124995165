import { FC } from "react";

import toy1 from "../assets/images/3d-toy-1.webp";
import toy2 from "../assets/images/3d-toy-2.webp";
import toy3 from "../assets/images/3d-toy-3.webp";
import toy4 from "../assets/images/3d-toy-4.webp";

import "./imagedefault.scss";

const ImageDefault: FC = () => (
  <div className="flex over-auto" style={{ display: "flex", overflow: "auto" }}>
    <div className="container-image">
      <img src={toy1} alt="toy1" className="image-item" />
      <img src={toy2} alt="toy2" className="image-item" />
      <img src={toy3} alt="toy3" className="image-item" />
      <img src={toy4} alt="toy4" className="image-item img-last" />
    </div>
  </div>
);

export default ImageDefault;
