export const styles = {
  defaultButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "12px",
    height: "40px",
    padding: "8px",
    boxShadow: "none !important",
  },
  square: {
    width: "8px",
    height: "8px",
    transform: "rotate(45deg)",
  },
};
