export const postInteractions = async (body: any) => {
  return fetch(`${process.env.REACT_APP_API_ENDPOIN}interactions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(body),
  });
};

export function queryToString(queryParams: { [key: string]: string }): string {
  const queryParamsArray = Object.entries(queryParams).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  );
  const queryString = queryParamsArray.join("&");
  return queryString;
}

export const getMessages = async (params: any) => {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOIN}messages?` + queryToString(params)
  );
};
