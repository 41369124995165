import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import axios, { AxiosError, Method } from "axios";
import { getStakingContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import { getNftInfo } from "src/utils/getNftInfo";

axios.defaults.baseURL = "https://nfts.nftcloud.net";

async function getNFTs(ids, collecttion) {
  const nfts = [];
  for (let i = 0; i < ids.length; i++) {
    const { name, image } = await getNftInfo(ids[i]);
    nfts.push({
      name: name,
      tokenURI: image,
      tokenId: ids[i],
      collection: collecttion,
    });
  }
  return nfts;
}

export const useGetItemsStaked = (
  packageId,
  collecttion,
  setIsLoading,
  isOpen
) => {
  const [items, setItems] = useState([]);
  const [fetched, setFetched] = useState(false);

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);

  useEffect(() => {
    const getItemsStaked = async (packageId) => {
      setIsLoading(true);
      const ids = await contract.methods.getPackageNfts(packageId).call();
      setFetched(true);
      setItems(await getNFTs(ids, collecttion));
      setIsLoading(false);
    };
    if (account && !fetched && isOpen) {
      getItemsStaked(packageId);
    }
  }, [account, web3, packageId, isOpen]);

  return items;
};
