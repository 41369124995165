import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "./useRefresh";
import useWeb3 from "../hooks/useWeb3";
import { getBuyNFTContract } from "src/utils/contractHelpers";

export const useCloudTokenPrice = () => {
  const [cloudTokenPrice, setCloudTokenPrice] = useState(0);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getBuyNFTContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchPrice = async () => {
      const res = await contract.methods.cloudTokenPrice().call();
      setCloudTokenPrice(res);
    };
    if (account) {
      fetchPrice();
    }
  }, [account, contract, fastRefresh]);

  return cloudTokenPrice;
};
