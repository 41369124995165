import { useState, useCallback, useRef, useEffect, useMemo } from "react";

import ImageDefault from "../components/ImageDefault";
import DropDownItem from "../components/DropDownItem";
import info from "../assets/icons/info.svg";
import "./cloudAi.scss";
import { LevelType } from "../types/level";
import Modal from "../components/modal/Modal";
import { ReactComponent as LogoIcon } from "../assets/icons/circle-loading.svg";
import Image2, { ImageData } from "../components/Image2/Image2";
import RecentItem from "../components/recent-item/RecentItem";
import { getMessages, postInteractions } from "../utils/api";
import { TransparentHeader } from "src/components/common/Header";

export interface HistoryType extends ImageData {
  isHighered: boolean;
  description: string;
}

const listLevel: { label: string; color: string; value: number }[] = [
  {
    value: 1,
    label: LevelType.Low,
    color: "#938FF2",
  },
  {
    value: 2,
    label: LevelType.Medium,
    color: "#01B976",
  },
  {
    value: 3,
    label: LevelType.High,
    color: "#FFA800",
  },
  {
    value: 4,
    label: LevelType.VeryHigh,
    color: "#FF4E63",
  },
];

const actionType = {
  HIGHER: "higher",
  CREATE: "create",
};

function CloudAI() {
  const [description, setDescription] = useState("");
  const [level, setLevel] = useState(2);
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [isOverview, setIsOverview] = useState(true);
  const [apiCount, setApiCount] = useState(0);
  const [selectedRecent, setSelectedRecent] = useState(0);
  const [isHighered, setIsHighered] = useState(false);
  const [openHistoryMobile, setOpenHistoryMobile] = useState(false);
  const [imageData, setImageData] = useState<any>({
    image: "",
    imageName: "",
    components: [],
    messageId: "",
    keyWord: "",
  });

  const [history, setHistory] = useState<HistoryType[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [percent, setPercent] = useState(0);

  const onChangeImageData = useCallback((data: any) => {
    setImageData(data);
  }, []);

  const onChangeLoading = useCallback((data: any) => {
    setLoading(data);
  }, []);

  const onChangeHighered = useCallback((data: any) => {
    setIsHighered(data);
  }, []);

  const onChangeHistory = useCallback((data: any) => {
    setHistory(data);
  }, []);

  const onChangeActiveHistory = useCallback((data: any) => {
    setSelectedRecent(data);
  }, []);

  const onChangeOpenHistoryMobile = useCallback((data: any) => {
    setOpenHistoryMobile(data);
  }, []);

  const onChangeCompleted = useCallback((data: any) => {
    setIsCompleted(data);
  }, []);

  const onChangePercent = useCallback((data: any) => {
    setPercent(data);
  }, []);

  const [action, setAction] = useState("");
  const [selected, setSelected] = useState(0);
  const [openModalImg, setOpenModalImg] = useState(false);
  const [apiCountImg, setApiCountImg] = useState(0);

  const handleHigher = useCallback(() => {
    setAction(actionType.HIGHER);
  }, []);
  const handleCreate = useCallback(() => {
    setAction(actionType.CREATE);
  }, []);

  const getCustomId = useCallback(() => {
    let customId;
    if (action === actionType.HIGHER) {
      customId = isHighered
        ? imageData.components[0].components[1].custom_id
        : imageData.components[0].components[selected - 1].custom_id;
    } else {
      customId = isHighered
        ? imageData.components[0].components[0].custom_id
        : imageData.components[1].components[selected - 1].custom_id;
    }
    return customId;
  }, [action, imageData.components, isHighered, selected]);

  const cantProcess = useCallback(
    () => !action || (!isHighered && !selected),
    [action, isHighered, selected]
  );

  const handleGetImage2 = useCallback(
    (
      type: any,
      quantity: any,
      timeAction: any,
      callBack: () => void,
      interval: any
    ) => {
      getMessages({
        description: imageData.keyWord,
        type: type,
        quality_type: quantity,
        start_action_time: timeAction,
        message_reference_id: imageData.messageId,
      })
        .then(async (res) => {
          const response = await res.json();
          if (response.data.success) {
            if (
              response.data.data &&
              response.data.data.attachments &&
              response.data.data.attachments.length
            ) {
              onChangeImageData({
                image: response.data.data.attachments[0].url,
                imageName: response.data.data.attachments[0].filename,
                components: response.data.data.components,
                messageId: response.data.data.id,
                keyWord: imageData.keyWord,
              });
              onChangeHistory([
                {
                  isHighered: type === 2 ? true : false,
                  description: imageData.keyWord,
                  image: response.data.data.attachments[0].url,
                  imageName: response.data.data.attachments[0].filename,
                  components: response.data.data.components,
                  messageId: response.data.data.id,
                  keyWord: imageData.keyWord,
                },
                ...history,
              ]);
              setApiCountImg(0);
              callBack();
              clearInterval(interval);
              onChangeCompleted(true);
              setTimeout(() => {
                onChangeLoading(false);
                setLoadingAction(false);
              }, 500);
            } else {
              if (apiCountImg > 50) {
                onChangeLoading(false);
                setLoadingAction(false);
                window.alert("Cannot generate image, please try again!");
                return;
              }
              setApiCountImg(apiCountImg + 1);
              setTimeout(() => {
                handleGetImage2(type, quantity, timeAction, callBack, interval);
              }, 5000);
            }
          } else {
            onChangeLoading(false);
            setLoadingAction(false);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 429) {
            onChangeLoading(false);
            setLoadingAction(false);
            window.alert(e.response.data);
          }
        });
    },
    [
      apiCountImg,
      history,
      imageData.keyWord,
      imageData.messageId,
      onChangeCompleted,
      onChangeHistory,
      onChangeImageData,
      onChangeLoading,
    ]
  );

  const handleAction = useCallback(
    async (data: any, cb: any) => {
      try {
        const timeAction = new Date().toISOString();
        setPercent(1);
        onChangePercent(0);
        onChangeCompleted(false);
        const res = await postInteractions({
          type: data.type,
          description: imageData.keyWord,
          custom_id: data.customId,
          message_id: data.messageId,
          quality_type: data.generateLevel,
        });
        const response = await res.json();
        if (!response.data) {
          onChangeLoading(true);
          setLoadingAction(true);
          const newProgressInterval = setInterval(() => {
            setPercent(
              (prev) => prev + Math.floor(Math.random() * (4 - 1) + 1)
            );
          }, 1000);
          setTimeout(() => {
            setApiCountImg(0);
            handleGetImage2(
              data.type,
              data.generateLevel,
              timeAction,
              cb,
              newProgressInterval
            );
          }, 10000);
        } else {
          // set notification here when prompt has been blocked
          // do something to turn on notification
          window.alert(response.data.error);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      handleGetImage2,
      imageData.keyWord,
      onChangeCompleted,
      onChangeLoading,
      onChangePercent,
    ]
  );

  const processAction = useCallback(() => {
    if (cantProcess()) return;
    const data = {
      type: action === actionType.HIGHER ? 2 : 3,
      messageId: imageData.messageId,
      generateLevel: level,
      customId: getCustomId(),
    };
    handleAction(data, () => {
      onChangeHighered(action === actionType.HIGHER);
      setAction("");
      setSelected(0);
      onChangeActiveHistory(0);
    });
  }, [
    action,
    cantProcess,
    getCustomId,
    handleAction,
    imageData.messageId,
    level,
    onChangeActiveHistory,
    onChangeHighered,
  ]);

  const saveImage = useCallback(async () => {
    const image = await fetch(imageData.image);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = imageData.imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [imageData.image, imageData.imageName]);

  const unsecuredCopyToClipboard = (text: any) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      // turn on notification here
      window.alert("Copied!");
    } catch (err) {
      // turn on notification here
      window.alert("Unable to copy to clipboard!");
    }
    document.body.removeChild(textArea);
  };

  const copyPrompt = async (text: any) => {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      // turn on notification here
      window.alert("Copied!");
    } else {
      unsecuredCopyToClipboard(text);
    }
  };

  const toggleDropDown = useCallback(() => {
    setDropdown(!dropdown);
  }, [dropdown]);
  const closeDropDown = useCallback(() => {
    setDropdown(false);
  }, []);

  const onChange = useCallback(({ target }: any) => {
    setDescription(target.value);
  }, []);

  const handleChangeLevel = useCallback((level: number) => {
    setLevel(level);
  }, []);

  const backHistory = useCallback(
    (index: number) => {
      const _history = { ...history[index] };
      setSelectedRecent(index);
      setIsHighered(_history.isHighered);
      setImageData({
        keyWord: _history.keyWord,
        image: _history.image,
        imageName: _history.imageName,
        //@ts-ignore
        components: _history.components,
        messageId: _history.messageId,
      });
    },
    [history]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropDown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closeDropDown, dropdown, dropdownRef]);

  const handleGetImage = useCallback(
    (timeAction: any, intervalId?: NodeJS.Timer) => {
      getMessages({
        description: description,
        type: 1,
        quality_type: level,
        start_action_time: timeAction,
      })
        .then(async (res) => {
          const response = await res.json();
          if (response.data.success) {
            if (
              response.data.data &&
              response.data.data.attachments &&
              response.data.data.attachments.length
            ) {
              setImageData({
                image: response.data.data.attachments[0].url,
                imageName: response.data.data.attachments[0].filename,
                components: response.data.data.components,
                messageId: response.data.data.id,
                keyWord: description,
              });

              setHistory((prev) => [
                {
                  isHighered: isHighered,
                  description: description,
                  image: response.data.data.attachments[0].url,
                  imageName: response.data.data.attachments[0].filename,
                  components: response.data.data.components,
                  messageId: response.data.data.id,
                  keyWord: description,
                },
                ...prev,
              ]);
              setSelectedRecent(0);
              setDescription("");
              setIsHighered(false);
              setIsOverview(false);
              setApiCount(0);
              if (intervalId) {
                clearInterval(intervalId);
              }
              setIsCompleted(true);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            } else {
              if (apiCount > 50) {
                setLoading(false);
                // set notification here when no result
                // do something to turn on notification
                window.alert("Cannot generate image, please try again!");
                return;
              }
              setApiCount(apiCount + 1);
              setTimeout(() => {
                handleGetImage(timeAction, intervalId);
              }, 5000);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          if (e.respons && e.response.status === 429) {
            // set notification here when call to many api
            // do something to turn on notification
            window.alert(e.response.data);
          }
          setLoading(false);
        });
    },
    [apiCount, description, isHighered, level]
  );

  const handleGenerateImage = useCallback(async () => {
    try {
      setAction("");
      const timeAction = new Date().toISOString();
      setIsCompleted(false);
      setPercent(0);
      const res = await postInteractions({
        type: 1,
        description: description,
        quality_type: level,
      });
      const response = await res.json();
      if (!response.data) {
        setLoading(true);
        const newProgressInterval = setInterval(() => {
          setPercent((prev) => prev + Math.floor(Math.random() * (4 - 1) + 1));
        }, 1000);
        setTimeout(() => {
          setApiCount(0);
          handleGetImage(timeAction, newProgressInterval);
        }, 10000);
      } else {
        // set notification here when prompt has been blocked
        // do something to turn on notification
        window.alert(response.data.error);
      }
    } catch (e) {
      console.log(e);
    }
  }, [description, handleGetImage, level]);

  const currentPercent = useMemo(() => {
    if (!isCompleted) {
      return percent > 99 ? 99 : percent;
    } else {
      return 100;
    }
  }, [isCompleted, percent]);

  return (
    <>
      <TransparentHeader isFixed isDark />
      <div className="container">
        <div
          className="container-content"
          // style={{ paddingRight: isOverview ? 0 : "15px" }}
          style={{ marginTop: "30px" }}
        >
          {isOverview === true && !loading ? (
            <div className="title">
              From imagination to reality, with the power of CloudAI
            </div>
          ) : null}
          <div
            className="input-view"
            style={{ paddingRight: !isOverview ? 0 : "15px" }}
          >
            <div
              className={`input-action ${
                loading && !loadingAction ? "input-border-loading" : ""
              }`}
            >
              {loading && !loadingAction ? (
                <span className="cloud-input w-full textarea" role="textbox">
                  <span className="text-content">{description}</span>
                </span>
              ) : (
                <input
                  disabled={loading}
                  value={description}
                  placeholder={`${
                    window?.innerWidth <= 600
                      ? "Try “female with her head back singing,..."
                      : "Try “cute 3D toy factory, with visualized production lines”"
                  }`}
                  className="input-value"
                  onChange={onChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && description.trim()) {
                      handleGenerateImage();
                    }
                  }}
                />
              )}

              <div
                className={`btn-generate center ${loading && "disabled"}`}
                onClick={() => {
                  if (description.trim() && !loading) {
                    handleGenerateImage();
                  }
                }}
              >
                Generate
              </div>
            </div>
            {isOverview === true && !loading ? (
              <div className="other-action">
                <div className="dropdown" ref={dropdownRef}>
                  {openModal && (
                    <Modal
                      open={openModal}
                      onClose={() => {
                        setOpenModal(false);
                      }}
                      className="modal-information-style-custom"
                    >
                      <div className="information-container">
                        This AICrew Bot has been trained to produce images that
                        favor artistic color, composition, and forms. This
                        parameter influences how strongly this training is
                        applied. Low stylization values produce images that
                        closely match the prompt but are less artistic. High
                        stylization values create images that are very artistic
                        but less connected to the prompt.
                      </div>
                    </Modal>
                  )}
                  <div className="drop-down center" onClick={toggleDropDown}>
                    Creative Level
                  </div>
                  {dropdown && (
                    <div className="outer-list top">
                      <ul>
                        {listLevel.map((o, i) => {
                          const first = i === 0;
                          const last = i === listLevel.length;
                          return (
                            <li
                              onClick={() => {
                                handleChangeLevel(o.value);
                                toggleDropDown();
                              }}
                              key={o.label}
                              className={`${
                                first ? "br-b-2" : last ? "br-t-2" : "br-2"
                              }`}
                            >
                              <div
                                className={`${
                                  level === o.value ? "p-1px" : ""
                                } w-full drop-down-border-gradient`}
                              >
                                <DropDownItem label={o.label} color={o.color} />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <img
                  src={info}
                  alt="info"
                  className="c-pointer"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
              </div>
            ) : null}
          </div>
          {!loading && isOverview === true && <ImageDefault />}
          {/* màn generated image hiển thị khi màn overview đang ẩn và loading xong hoặc khi đang thực hiện loading các action higher,create */}
          {((isOverview === false && !loading) || loadingAction === true) && (
            <Image2
              action={action}
              imageData={imageData}
              isHighered={isHighered}
              openHistoryMobile={openHistoryMobile}
              selected={selected}
              openModalImg={openModalImg}
              processAction={processAction}
              cantProcess={cantProcess}
              setSelected={setSelected}
              onChangeOpenHistoryMobile={onChangeOpenHistoryMobile}
              handleHigher={handleHigher}
              handleCreate={handleCreate}
              saveImage={saveImage}
              setOpenModalImg={setOpenModalImg}
              copyPrompt={copyPrompt}
            />
          )}
          {loading && (
            <div
              className="w-full center-container cloud-loading-container"
              style={{ background: loadingAction ? "rgba(0,0,0,0.8)" : "" }}
            >
              <div className="border-logo-1 center-container animate-ping"></div>
              <div className="border-logo-2 center-container animate-ping"></div>
              <div>
                <LogoIcon />
              </div>
              <div className="generate-progress-bar">
                <div
                  style={{
                    height: "3px",
                    width: "100%",
                    background: "#FFFFFF",
                  }}
                ></div>
                <div
                  className="progress-bar-percent"
                  style={{
                    width: `${currentPercent}%`,
                  }}
                >
                  {currentPercent > 0 && (
                    <span className="percent-number">{currentPercent}%</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {!isOverview && (
            <div>
              <div
                className={openHistoryMobile ? "overlay-recent--left" : ""}
                onClick={() => onChangeOpenHistoryMobile(false)}
              ></div>
              <div className={`${openHistoryMobile ? "block" : ""} right-bar`}>
                <div className="recent-title">Recent</div>
                {history.map((o, i) => (
                  <RecentItem
                    key={`history-${i}`}
                    image={o.image}
                    isSelected={selectedRecent === i}
                    onSelect={() => {
                      setSelectedRecent(i);
                      backHistory(i);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CloudAI;
