import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { transferNFT } from "../utils/callHelpers";
import { getNFTContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useTransferNFT = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getNFTContract(web3);

  const handleTransferNFT = useCallback(
    async (to, tokenId) => {
      const txHash = await transferNFT(account, contract, to, tokenId);
      return txHash;
    },
    [account, contract]
  );

  return { onTransferNFT: handleTransferNFT };
};
