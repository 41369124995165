import { useEffect, useState } from "react";
import { getNFTContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useNftBalance = (account) => {
  const [balance, setBalance] = useState(0);
  const [nftStaking, setNftStaking] = useState(0);
  const [nftValue, setNftValue] = useState(0);

  const web3 = useWeb3();
  const contract = getNFTContract(web3);

  useEffect(() => {
    const fetchBalance = async () => {
      const _balance = await contract.methods.balanceOf(account).call();
      setBalance(_balance);

      const _nftStaking = await contract.methods
        .countTotalLockedToken(account)
        .call();
      setNftStaking(_nftStaking);

      const _nftValue = await contract.methods
        .getTotalTokenValue(account)
        .call();
      setNftValue(_nftValue);
    };
    if (account) {
      fetchBalance();
    }
  }, [account, web3]);

  return [balance, nftStaking, nftValue];
};
