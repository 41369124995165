import { VStack, Text, Icon, HStack } from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import { useWalletModal, Login } from "../../../components/common/WalletModal";
// import useCountdown from "../../../hooks/useCountdown";
import { toast } from "react-toastify";

const TAG_MAPS: any = {
  ordinary: {
    name: "The Ordinary",
    title: "Normal Collection",
    color: "#E4E4E4",
    background:
      "linear-gradient(91.61deg, #C8C8C8 0%, #FDFDFD 18.75%, #C2C2C2 86.46%, #FFFFFF 100%)",
    border:
      "linear-gradient(91.61deg, #FFFFFF 0%, #FFFFFF 0.52%, #C2C2C2 15.62%, #FDFDFD 78.65%, #CCCCCC 100%)",
  },
  supremacy: {
    name: "The Supremacy",
    title: "V.I.P Collection",
    color: "#FFDB6A",
    background:
      "linear-gradient(90.8deg, #FFEA97 0%, #FFDB6A 59.3%, #FFCE4A 76.46%, #FFC133 87.38%, #FFA800 99.87%, #FFB320 99.87%)",
    border:
      "linear-gradient(90.89deg, #FFA800 0%, #FFC133 13.26%, #FFCE4A 26.2%, #FFDB6A 38.82%, #FFEA97 100%)",
  },
};

export default function Tag(props: any) {
  const { name, text, onOpen } = props;

  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account as any
  );
  // const targetDate = 1676998800000;
  // const [days, hours, minutes, seconds, countDown] = useCountdown(targetDate);

  return (
    <VStack
      background={TAG_MAPS[name].background}
      borderRadius="8px"
      padding={["12px", "12px", "16px"]}
      alignItems="flex-start"
      w={["50%", "50%", "50%"]}
      zIndex={1}
      style={{
        border: "4px solid",
        borderImageSource: TAG_MAPS[name].border,
        borderImageSlice: 2,
        // create border image radius outside
      }}
    >
      <Text
        lineHeight={["17px", "17px", "37px"]}
        fontSize={["14px", "14px", "30px"]}
        fontWeight={600}
        whiteSpace="nowrap"
        fontFamily="Clash Display, sans-serif"
      >
        {TAG_MAPS[name].name}
      </Text>
      <Text
        fontFamily="Poppins, sans-serif"
        mt="0px !important"
        fontSize={["12px", "16px", "16px"]}
        fontWeight={600}
      >
        {TAG_MAPS[name].title}
      </Text>

      {/* {countDown > 0 && (
        <Text>
          Ends in: {days}d {hours}h {minutes}m {seconds}s
        </Text>
      )} */}

      <HStack
        w="100%"
        py="12px"
        background={TAG_MAPS[name].color}
        borderRadius="4px"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={account ? onOpen : onPresentConnectModal}
        fontFamily="Poppins, sans-serif"
      >
        <img src="/token.png" width="25px" height="25px" />
        <Text fontSize={["18px", "18px", "16px"]} fontWeight={500}>
          {text}
        </Text>
      </HStack>
    </VStack>
  );
}
