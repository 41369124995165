import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { colors, customGlobal } from "./theme";
import { ModalProvider } from "./components/common/ModalUI";
import useEagerConnect from "./hooks/useEagerConnect";

const theme = extendTheme({ colors, ...customGlobal });

function App() {
  useEagerConnect();

  return (
    <ChakraProvider theme={theme}>
      <ModalProvider>
        <RouterProvider router={router} />
      </ModalProvider>
    </ChakraProvider>
  );
}

export default App;
