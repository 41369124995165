import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getStakingContract, getNFTContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import BigNumber from "bignumber.js";
import useRefresh from "./useRefresh";

export const useGetTotalReward = () => {
  const [totalReward, setTotalReward] = useState(new BigNumber(0));

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const getTotalReward = async () => {
      const _totalReward = await contract.methods.totalReward(account).call();
      setTotalReward(new BigNumber(_totalReward));
    };
    if (account) {
      getTotalReward();
    }
  }, [account, web3, fastRefresh]);

  return totalReward;
};
