import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "./useRefresh";
import { getNFTContract } from "../utils/contractHelpers";
import { SELL_NFT_CONTRACT } from "src/config";
import useWeb3 from "../hooks/useWeb3";

export const useGetApprovedForAll = () => {
  const [approved, setApproved] = useState(false);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getNFTContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchAllowance = async () => {
      const res = await contract.methods
        .isApprovedForAll(account, SELL_NFT_CONTRACT)
        .call();
      setApproved(res);
    };
    if (account) {
      fetchAllowance();
    }
  }, [account, contract, fastRefresh]);

  return approved;
};
