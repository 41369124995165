import { Box, Text } from "@chakra-ui/react";

export default function PageTitle() {
  return (
    <Box mt={[4, 4, 16]} ml={30}>
      <Text
        fontFamily="Clash Display, sans-serif"
        color="white"
        fontSize={["24px", "24px", "32px"]}
        fontWeight={600}
        lineHeight="30px"
      >
        Fresh from the cloud,
      </Text>
      <Text
        fontFamily="Clash Display, sans-serif"
        color="white"
        fontSize={["24px", "24px", "32px"]}
        fontWeight={600}
        lineHeight="30px"
      >
        choose your box of enchantment
      </Text>
    </Box>
  );
}
