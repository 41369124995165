import { Box, Stack, VStack } from "@chakra-ui/react";
import { TransparentHeader } from "src/components/common/Header";
import PageTitle from "src/pages/sale/components/PageTitle";
import ListItems from "src/pages/sale/components/ListItems";
import ListTags from "src/pages/sale/components/ListTags";
import backgroundImage from "src/assets/HeroImage.png";
import mainBackgroundImage from "src/assets/cloud.png";
import useTokenBalance from "../../hooks/useTokenBalance";
import { useAllowance } from "src/hooks/useAllowance";
import { CLOUD_TOKEN, USDT_TOKEN } from "src/config";
import { SALE_NFT_CONTRACT } from "src/config";
// import useCountdown from "../../hooks/useCountdown";
// import CountDown from "./components/CountDown";

function SalePage() {
  const cloudBalance = useTokenBalance(CLOUD_TOKEN).balance;
  const allowance = useAllowance(CLOUD_TOKEN, SALE_NFT_CONTRACT);
  // const targetDate = 1676422800000;
  // const [days, hours, minutes, seconds, countDown] = useCountdown(targetDate);

  return (
    <Box
      height="100%"
      width="auto"
      style={{
        backgroundImage: `url("${mainBackgroundImage}"), linear-gradient(180deg, #181F51 0%, #111745 20.31%, #0C103A 43.23%, #070A2E 66.67%, #040726 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundAttachment: "fixed",
        backgroundPosition: "50% 100%",
      }}
      overflowX="hidden"
      // overflowY={["auto", "auto", "hidden"]}
    >
      <TransparentHeader isFixed isDark />
      <Stack
        height="100%"
        direction={["column", "column", "row"]}
        spacing={[8, 54]}
        paddingTop="70px"
        // opacity={countDown > 0 ? "0.2" : "1"}
        // userSelect={countDown > 0 ? "none" : "auto"}
        // pointerEvents={countDown > 0 ? "none" : "all"}
      >
        <VStack w={["100%", "100%", "40%"]} spacing={4} align="stretch">
          <PageTitle />
          <ListItems />
        </VStack>
        <Box
          style={{
            position: "relative",
            display: "flex",
          }}
          w={["100%", "100%", "60%"]}
          h={["100%", "100%", "100%"]}
          justifyContent="center"
          alignItems="flex-end"
          background={`url("${backgroundImage}")`}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="right bottom"
        >
          <ListTags balance={cloudBalance} allowance={allowance.toNumber()} />
        </Box>
      </Stack>
      {/* {countDown > 0 && (
        <div
          style={{
            fontSize: "40px",
            position: "fixed",
            top: "calc(50% - 100px)",
            width: "100%",
            textAlign: "center",
            color: "#FFB524",
          }}
        >
          <div>Presale Countdown</div>
          <CountDown
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        </div>
      )} */}
    </Box>
  );
}

export default SalePage;
