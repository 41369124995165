import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { approveForAll } from "../utils/callHelpers";
import { getNFTContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import { SELL_NFT_CONTRACT } from "src/config";

export const useApproveForAll = () => {
  const { account } = useWeb3React();

  const web3 = useWeb3();
  const contract = getNFTContract(web3);

  const handleApprove = useCallback(async () => {
    const txHash = await approveForAll(contract, SELL_NFT_CONTRACT, account);
    return txHash;
  }, [account, contract]);

  return { onApproveForAll: handleApprove };
};
