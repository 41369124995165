import React, { useState } from "react";

import {
  Text,
  HStack,
  Stack,
  Icon,
  VStack,
  Button,
  useDisclosure,
  Flex,
  Collapse,
  Grid,
  Box,
  GridItem,
} from "@chakra-ui/react";

import moment from "moment";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import { useHarvest } from "src/hooks/useHarvest";
import { useGetItemsStaked } from "src/hooks/useGetItemsStaked";

import SmallItem from "./SmallItem";

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

function LockIcon() {
  return (
    <Icon>
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.25 9H13.5V5.25C13.5 4.05653 13.0259 2.91193 12.182 2.06802C11.3381 1.22411 10.1935 0.75 9 0.75C7.80653 0.75 6.66193 1.22411 5.81802 2.06802C4.97411 2.91193 4.5 4.05653 4.5 5.25V9H3.75C2.95462 9.00087 2.19206 9.31722 1.62964 9.87964C1.06722 10.4421 0.750869 11.2046 0.75 12V20.25C0.750869 21.0454 1.06722 21.8079 1.62964 22.3704C2.19206 22.9328 2.95462 23.2491 3.75 23.25H14.25C15.0454 23.2491 15.8079 22.9328 16.3704 22.3704C16.9328 21.8079 17.2491 21.0454 17.25 20.25V12C17.2491 11.2046 16.9328 10.4421 16.3704 9.87964C15.8079 9.31722 15.0454 9.00087 14.25 9ZM12 9H6V5.25C6 4.45435 6.31607 3.69129 6.87868 3.12868C7.44129 2.56607 8.20435 2.25 9 2.25C9.79565 2.25 10.5587 2.56607 11.1213 3.12868C11.6839 3.69129 12 4.45435 12 5.25V9Z"
          fill="#FFB524"
        />
      </svg>
    </Icon>
  );
}

function UnLockIcon() {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.25 9H9V5.25C9 4.45435 9.31607 3.69129 9.87868 3.12868C10.4413 2.56607 11.2044 2.25 12 2.25C12.7956 2.25 13.5587 2.56607 14.1213 3.12868C14.6839 3.69129 15 4.45435 15 5.25C15 5.44891 15.079 5.63968 15.2197 5.78033C15.3603 5.92098 15.5511 6 15.75 6C15.9489 6 16.1397 5.92098 16.2803 5.78033C16.421 5.63968 16.5 5.44891 16.5 5.25C16.5 4.05653 16.0259 2.91193 15.182 2.06802C14.3381 1.22411 13.1935 0.75 12 0.75C10.8065 0.75 9.66193 1.22411 8.81802 2.06802C7.97411 2.91193 7.5 4.05653 7.5 5.25V9H6.75C5.95462 9.00087 5.19206 9.31722 4.62964 9.87964C4.06722 10.4421 3.75087 11.2046 3.75 12V20.25C3.75087 21.0454 4.06722 21.8079 4.62964 22.3704C5.19206 22.9328 5.95462 23.2491 6.75 23.25H17.25C18.0454 23.2491 18.8079 22.9328 19.3704 22.3704C19.9328 21.8079 20.2491 21.0454 20.25 20.25V12C20.2491 11.2046 19.9328 10.4421 19.3704 9.87964C18.8079 9.31722 18.0454 9.00087 17.25 9Z"
          fill="#181F51"
        />
      </svg>
    </Icon>
  );
}

function SeeMoreIcon() {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.11875 9.29006L11.9987 13.1701L15.8787 9.29006C16.2687 8.90006 16.8987 8.90006 17.2887 9.29006C17.6788 9.68006 17.6788 10.3101 17.2887 10.7001L12.6987 15.2901C12.3087 15.6801 11.6787 15.6801 11.2887 15.2901L6.69875 10.7001C6.30875 10.3101 6.30875 9.68006 6.69875 9.29006C7.08875 8.91006 7.72875 8.90006 8.11875 9.29006Z"
          fill="#FFB524"
        />
      </svg>
    </Icon>
  );
}

function SeeLessIcon() {
  return (
    <Icon>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.3695 15.29L12.4895 11.41L8.60953 15.29C8.21953 15.68 7.58953 15.68 7.19953 15.29C6.80953 14.9 6.80953 14.27 7.19953 13.88L11.7895 9.29002C12.1795 8.90002 12.8095 8.90002 13.1995 9.29002L17.7895 13.88C18.1795 14.27 18.1795 14.9 17.7895 15.29C17.3995 15.67 16.7595 15.68 16.3695 15.29Z"
          fill="#FFB524"
        />
      </svg>
    </Icon>
  );
}

export default function ClaimItem(props: any) {
  const { isSelected, item, cloudTokenPrice } = props;
  const { isOpen, onToggle } = useDisclosure();

  const isUnLock = item.lockUntil < new Date().getTime() / 1e3;
  const [claimInProcess, setClaimInProcess] = useState(false);
  const { onHarvest } = useHarvest();
  const [isLoading, setIsLoading] = useState(true);

  const items = useGetItemsStaked(
    item.id,
    item.apr === 72 ? "Normal" : "V.I.P",
    setIsLoading,
    isOpen
  );

  return (
    <VStack
      w="100%"
      background="#FFFFFF"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.16)"
      borderRadius="10px"
      border={isSelected && "5px solid rgba(255, 181, 36, 0.4)"}
    >
      <HStack
        borderTopRadius="5px"
        w="100%"
        padding="10px 20px"
        background="#181F51"
        justifyContent="space-between"
      >
        <Text
          fontFamily="Poppins, sans-serif"
          fontWeight={600}
          fontSize="12px"
          lineHeight="18px"
          color="#938FF2"
        >
          Staked ID: #{item.id}
        </Text>
        <Text
          color="white"
          fontFamily="Poppins, sans-serif"
          fontWeight={600}
          fontSize="12px"
          lineHeight="18px"
          display={["none", "none", "block"]}
        >
          Staked: {moment(item.lockFrom * 1000).format("DD/MM/yyyy - HH:MM")}
        </Text>
      </HStack>
      <HStack w="full" padding="20px" justifyContent="space-between">
        {isUnLock ? <UnLockIcon /> : <LockIcon />}
        <VStack>
          <Text
            fontFamily="Poppins, sans-serif"
            fontWeight={500}
            fontSize="12px"
            lineHeight="18px"
            color=" rgba(24, 31, 81, 0.4)"
          >
            Total NFT value
          </Text>
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            align="left"
          >
            ${getFullDisplayBalance(item.amount, 18, 2)}
          </Text>
        </VStack>
        <VStack>
          <Text
            fontFamily="Poppins, sans-serif"
            fontWeight={500}
            fontSize="12px"
            lineHeight="18px"
            color=" rgba(24, 31, 81, 0.4)"
          >
            APY
          </Text>
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            align="left"
          >
            {item.apr}%
          </Text>
        </VStack>
        <VStack display={["none", "none", "block"]}>
          <Text
            fontFamily="Poppins, sans-serif"
            fontWeight={500}
            fontSize="12px"
            lineHeight="18px"
            color=" rgba(24, 31, 81, 0.4)"
          >
            Duration
          </Text>
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            align="left"
          >
            2 years
          </Text>
        </VStack>
        <VStack display={["none", "none", "block"]}>
          <Text
            fontFamily="Poppins, sans-serif"
            fontWeight={500}
            fontSize="12px"
            lineHeight="18px"
            color=" rgba(24, 31, 81, 0.4)"
          >
            Unlock date
          </Text>
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            align="left"
          >
            {moment(item.lockUntil * 1000).format("DD/MM/yyyy - HH:MM")}
          </Text>
        </VStack>
        <Button
          m="0 !important"
          {...activeButtonStyle}
          display={["none", "none", "block"]}
          disabled={claimInProcess}
          onClick={async () => {
            setClaimInProcess(true);
            try {
              await onHarvest(item.id);
              setClaimInProcess(false);
            } catch (e) {
              setClaimInProcess(false);
            }
          }}
        >
          {claimInProcess ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
              style={{ color: "#181F51" }}
            ></div>
          ) : (
            <>Claim</>
          )}
        </Button>
        <Box onClick={onToggle} display={["block", "block", "none"]}>
          {isOpen ? <SeeLessIcon /> : <SeeMoreIcon />}
        </Box>
      </HStack>
      <Flex
        w="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Collapse style={{ width: "100%" }} in={isOpen} animateOpacity>
          {!isLoading && (
            <VStack w="100%">
              <VStack
                display={["block", "none", "none"]}
                w="100%"
                background="rgba(24, 31, 81, 0.05)"
                p={["12px", "20px", "20px"]}
              >
                <Stack w="100%" direction="row" justifyContent="space-between">
                  <Text
                    color="rgba(24, 31, 81, 0.4)"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    Daily APY
                  </Text>
                  <Text
                    color="#06AE86"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    0.64%
                  </Text>
                </Stack>
                <Stack w="100%" direction="row" justifyContent="space-between">
                  <Text
                    color="rgba(24, 31, 81, 0.4)"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    Duration
                  </Text>
                  <Text
                    color="#181F51"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    2 years
                  </Text>
                </Stack>
                <Stack w="100%" direction="row" justifyContent="space-between">
                  <Text
                    color="rgba(24, 31, 81, 0.4)"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    Date
                  </Text>
                  <Text
                    color="#181F51"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    {moment(item.lockFrom * 1000).format("DD/MM/yyyy - HH:MM")}
                  </Text>
                </Stack>
                <Stack w="100%" direction="row" justifyContent="space-between">
                  <Text
                    color="rgba(24, 31, 81, 0.4)"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    Unlock date
                  </Text>
                  <Text
                    color="#181F51"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    {moment(item.lockUntil * 1000).format("DD/MM/yyyy - HH:MM")}
                  </Text>
                </Stack>
                <Stack w="100%" direction="row" justifyContent="space-between">
                  <Text
                    color="rgba(24, 31, 81, 0.4)"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    Earned
                  </Text>
                  <Text
                    color="#181F51"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="21px"
                  >
                    {((item.earned * cloudTokenPrice) / 1e18 / 1e8).toFixed(2)}{" "}
                    USDT{" "}
                    <small>
                      (~ {getFullDisplayBalance(item.earned, 18, 2)} CLOUD)
                    </small>
                  </Text>
                </Stack>
              </VStack>
              <Grid
                w="100%"
                background="rgba(24, 31, 81, 0.05)"
                templateColumns={[
                  "repeat(3, 1fr)",
                  "repeat(4, 1fr)",
                  "repeat(8, 1fr)",
                ]}
                gap={[2, 6, 6]}
                p={["12px", "20px", "20px"]}
                mt="0px !important"
              >
                {items.map((item) => (
                  <GridItem w="100%" key={item.tokenId}>
                    <SmallItem item={item} />
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}
          {isLoading && (
            <div
              style={{
                background: "rgba(24, 31, 81, 0.05)",
                textAlign: "center",
                padding: "30px 0",
              }}
            >
              <div
                className="spinner-border text-light"
                role="status"
                style={{ color: "#181F51" }}
              ></div>
            </div>
          )}
          <Box display={["block", "block", "none"]} p="12px" w="full">
            <Button m="0 !important" {...activeButtonStyle} w="full">
              Claim
            </Button>
          </Box>
        </Collapse>
        <HStack display={["none", "none", "flex"]} padding="10px">
          {isOpen ? <SeeLessIcon /> : <SeeMoreIcon />}
          <Text
            color="#FFB524"
            fontFamily="Poppins, sans-serif"
            fontWeight={500}
            fontSize="16px"
            lineHeight="24px"
            onClick={onToggle}
            cursor="pointer"
          >
            {!isOpen ? "See more" : "See less"}
          </Text>
        </HStack>
      </Flex>
    </VStack>
  );
}
