import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getStakingContract } from "../utils/contractHelpers";
import { multicall } from "src/utils/callHelpers";
import useWeb3 from "../hooks/useWeb3";
import BigNumber from "bignumber.js";
import stakeAbi from "../config/abi/staking.json";
import { STAKING_NFT_CONTRACT } from "src/config";

async function getStakes(ids, contract) {
  const calls = [];
  const stakings = [];

  for (let i = 0; i < ids.length; i++) {
    calls.push({
      address: STAKING_NFT_CONTRACT,
      name: "packages",
      params: [ids[i]],
    });
  }
  const _res = await multicall(stakeAbi, calls);

  for (let i = 0; i < ids.length; i++) {
    const res = _res[i];
    const pendingReward = await contract.methods
      .pendingReward(parseInt(res.id._hex))
      .call();
    stakings.push({
      lockFrom: parseInt(res.lockFrom._hex),
      lockUntil: parseInt(res.lockUntil._hex),
      id: parseInt(res.id._hex),
      amount: new BigNumber(res.amount._hex),
      lastHarvest: res.lastHarvest,
      apr: parseInt(res.apr._hex),
      earned: new BigNumber(pendingReward),
    });
  }
  return stakings;
}

export const useFetchStake = (setIsLoading, reloadTime) => {
  const [stakeIds, setStakeIds] = useState([]);
  const { account } = useWeb3React();

  const web3 = useWeb3();
  const contract = getStakingContract(web3);

  useEffect(() => {
    const fetchStake = async () => {
      setIsLoading(true);
      const ids = await contract.methods.getUserPackageIds(account).call();
      setStakeIds(await getStakes(ids, contract));
      setIsLoading(false);
    };
    if (account) {
      fetchStake();
    }
  }, [account, reloadTime]);

  return stakeIds;
};
