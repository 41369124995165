import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { multicall } from "src/utils/callHelpers";
import BigNumber from "bignumber.js";
import stakeTokenAbi from "../../config/abi/stakingToken.json";
import { STAKING_TOKEN_CONTRACT } from "src/config";

async function getStakePoolInfo(pids, account) {
  const calls1 = [];
  const calls2 = [];
  const stakings = [];

  for (let i = 0; i < pids.length; i++) {
    calls1.push({
      address: STAKING_TOKEN_CONTRACT,
      name: "userInfo",
      params: [pids[i], account],
    });
    calls2.push({
      address: STAKING_TOKEN_CONTRACT,
      name: "pendingReward",
      params: [pids[i], account],
    });
  }
  const res1 = await multicall(stakeTokenAbi, calls1);
  const res2 = await multicall(stakeTokenAbi, calls2);

  for (let i = 0; i < pids.length; i++) {
    stakings.push({
      staked: new BigNumber(res1[i].amount._hex)
        .div(1e18)
        .toNumber()
        .toFixed(4),
      earned: new BigNumber(res2[i][0]._hex).div(1e18).toNumber().toFixed(8),
    });
  }
  return stakings;
}

export const useFetchStake = (setIsLoading, reloadTime) => {
  const [pools, setPools] = useState([]);
  const { account } = useWeb3React();

  useEffect(() => {
    const fetchStake = async (account) => {
      // setIsLoading(true);
      setPools(await getStakePoolInfo([0, 1], account));
      setIsLoading(false);
    };
    if (account) {
      fetchStake(account);
    }
  }, [account, reloadTime]);

  return pools;
};
