import React from "react";
import { Box, Stack, Button } from "@chakra-ui/react";
import { TransparentHeader } from "src/components/common/Header";
import { useNavigate } from "react-router-dom";

import mainBackgroundImage from "src/assets/cloud.png";
import PageHeader from "./components/PageHeader";
import Pool from "./components/Pool";

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

const inactiveButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "rgba(24, 31, 81, 0.4)",
  background: "unset",
};

function StakePage() {
  const [activeLeftTab, setActiveLeftTab] = React.useState(1);
  const navigate = useNavigate();

  const handleSwitchLeftTab = (tab: number) => {
    setActiveLeftTab(tab);
    navigate(`/staking?tab=${tab === 1 ? "stake" : "claim"}`);
  };

  const getLeftTabStatus = (tab: number) => {
    return activeLeftTab === tab
      ? activeButtonStyle
      : {
          ...inactiveButtonStyle,
          _hover: {
            background: "#FFB524",
            color: "#181F51",
          },
        };
  };

  return (
    <Box
      height="100%"
      width="auto"
      style={{
        backgroundImage: `url("${mainBackgroundImage}"), linear-gradient(180deg, #181F51 0%, #111745 20.31%, #0C103A 43.23%, #070A2E 66.67%, #040726 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundAttachment: "fixed",
        backgroundPosition: "50% 100%",
      }}
      overflowX="hidden"
    >
      <TransparentHeader isFixed isDark />
      <Stack
        paddingTop="130px"
        paddingX="30px"
        height="100%"
        direction="column"
        color="white"
        alignItems="center"
      >
        <Box height="38px" background="white" borderRadius="8px" mb="34px">
          <Box
            width="fit-content"
            borderRadius="8px"
            background="rgba(255, 181, 36, 0.4)"
            display={["flex", "flex", "flex"]}
          >
            <Button
              onClick={() => handleSwitchLeftTab(1)}
              m="0 !important"
              {...getLeftTabStatus(1)}
            >
              Stake
            </Button>
            <Button
              onClick={() => handleSwitchLeftTab(2)}
              m="0 !important"
              {...getLeftTabStatus(2)}
            >
              Claiming
            </Button>
          </Box>
        </Box>

        <Stack
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems={["center", "center", "flex-end"]}
          direction={["column", "column", "row"]}
          gap="20px"
        >
          <PageHeader />
          <Stack
            display="flex"
            flexWrap="wrap"
            width={["100%", "100%", "80%"]}
            margin="0 auto"
            gap="20px"
            direction={["column", "column", "row"]}
          >
            <Pool name="ordinary" type={1} />
            <Pool name="supremacy" type={2} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default StakePage;
