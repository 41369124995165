import React from "react";
import { VStack, Stack, HStack, Text, Image } from "@chakra-ui/react";
import NFTLockedImage from "src/assets/nft-locked.svg";
import ValueLockedImage from "src/assets/value-locked.svg";
import TokenImage from "src/assets/token.svg";
import { useFetchPoolInfo } from "src/hooks/useFetchPoolInfo";
import { getFullDisplayBalance } from "src/utils/formatBalance";

export default function PageHeader() {
  const poolInfo = useFetchPoolInfo();

  return (
    <VStack>
      <VStack justifyContent="center" alignItems="center">
        <Text
          fontFamily="Clash Display, sans-serif"
          fontWeight={600}
          fontSize="64px"
          lineHeight="79px"
          color="#FFB524"
        >
          NFTCloud
        </Text>
        <Text
          fontFamily="Clash Display, sans-serif"
          fontWeight={600}
          fontSize="20px"
          lineHeight="25px"
        >
          Stake $NFT & NFTs to get profit
        </Text>
      </VStack>
      <Stack
        direction={["column", "column", "row"]}
        gap={["20px", "20px", "130px"]}
        marginTop="40px !important"
      >
        <HStack>
          <Image w="60px" h="60px" src={NFTLockedImage} />
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Clash Display, sans-serif"
              fontWeight={600}
              fontSize="20px"
              lineHeight="25px"
            >
              Total NFT locked
            </Text>
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={700}
              fontSize="26px"
              lineHeight="39px"
              color="#FFB524"
              mt="0 !important"
            >
              {poolInfo.totalNftLocked}
            </Text>
          </VStack>
        </HStack>
        <HStack>
          <Image w="60px" h="60px" src={ValueLockedImage} />
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Clash Display, sans-serif"
              fontWeight={600}
              fontSize="20px"
              lineHeight="25px"
            >
              Total value locked
            </Text>
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={700}
              fontSize="26px"
              lineHeight="39px"
              color="#FFB524"
              mt="0 !important"
            >
              ${getFullDisplayBalance(poolInfo.totalValueLocked, 18, 0)}
            </Text>
          </VStack>
        </HStack>
        <HStack>
          <Image w="60px" h="60px" src={TokenImage} />
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Clash Display, sans-serif"
              fontWeight={600}
              fontSize="20px"
              lineHeight="25px"
            >
              Total earned
            </Text>
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={700}
              fontSize="26px"
              lineHeight="39px"
              color="#FFB524"
              mt="0 !important"
            >
              {getFullDisplayBalance(poolInfo.totalEarn, 18, 0)} CLOUD
            </Text>
          </VStack>
        </HStack>
      </Stack>
    </VStack>
  );
}
