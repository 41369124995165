/* eslint-disable react/require-default-props */

import {
  Box,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  ModalCloseButton,
} from "@chakra-ui/react";
import React from "react";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import { Link, useLocation } from "react-router-dom";
import twitter from "src/assets/twitter.png";
import telegram from "src/assets/telegram.png";
import discord from "src/assets/discord.png";
import mobileClose from "src/assets/mobileClose.png";
import { MENU } from "../Header/TransparentHeader";
import OrangeButton from "../Button/OrangeButton";
import { useWalletModal, Login } from "../WalletModal";
import wallet from "src/assets/wallet.png";
import HotSale from "src/assets/hotsale.png";
import useCountdown from "../../../hooks/useCountdown";

interface IMobileMenuModal {
  isOpen: boolean;
  onClose: () => void;
}

function MobileMenuModal(props: IMobileMenuModal) {
  const { isOpen, onClose } = props;
  const { pathname } = useLocation();

  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    : null;
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account as any
  );

  const endTime = 1691028000000;
  const [days, hours, minutes, seconds, countDown] = useCountdown(endTime);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        background="rgba(24, 31, 81, 0.8)"
        backdropFilter="blur(10px)"
      />
      <ModalContent marginX="24px" borderRadius="20px" overflow="hidden">
        <Flex
          justify="space-between"
          align="center"
          padding="10px 20px"
          background="global.dividerColor"
        >
          <Text
            color="global.yellowOrange"
            fontSize="24px"
            fontWeight="700"
            fontFamily="Clash Display, sans-serif"
          >
            Menu
          </Text>
          <ModalCloseButton variant="unstyled" width="34px" height="34px">
            <Image src={mobileClose} width="34px" height="34px" />
          </ModalCloseButton>
        </Flex>
        <ModalBody width="100%" padding="0" height="100%">
          <Box padding="12px">
            <Box marginBottom="82px">
              {MENU.filter((item) => item?.link !== "/stake")
                .filter(
                  (item) => countDown <= 0 || item?.link !== "/staking-token"
                )
                .map((item) => {
                  return (
                    <Box
                      height="48px"
                      display="flex"
                      alignItems="center"
                      width="100%"
                      background={
                        pathname === item?.link
                          ? "rgba(255, 181, 36, 0.2)"
                          : "white"
                      }
                      padding="12px"
                      borderRadius="8px"
                      color={
                        pathname === item?.link
                          ? "global.yellowOrange"
                          : "global.dividerColor"
                      }
                      fontSize="16px"
                      fontWeight="700"
                      key={item.link}
                    >
                      {!item?.external && (
                        <Link to={item?.link}>{item?.name}</Link>
                      )}
                      {item?.external && <a href={item?.link}>{item?.name}</a>}
                    </Box>
                  );
                })}
            </Box>
            <Box>
              <OrangeButton height="54px" width="100%" borderRadius="8px">
                {account ? (
                  <Flex
                    fontSize="16px"
                    color="global.dividerColor"
                    fontWeight="700"
                    align="center"
                    justify="center"
                    onClick={() => {
                      onPresentAccountModal();
                    }}
                    height="100%"
                  >
                    <Image
                      src={wallet}
                      alt="wallet"
                      width="24px"
                      height="24px"
                      marginRight="10px"
                    />
                    <Text fontFamily="Poppins, sans-serif">
                      {accountEllipsis}
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    fontSize="16px"
                    color="global.dividerColor"
                    fontWeight="700"
                    align="center"
                    justify="center"
                    onClick={() => {
                      onPresentConnectModal();
                    }}
                    height="100%"
                  >
                    <Text fontFamily="Poppins, sans-serif">Connect Wallet</Text>
                  </Flex>
                )}
              </OrangeButton>
            </Box>
            <Box padding="24px 0px 12px 0px">
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img src="/favicon.png" width="20" height="20" />
                </div>{" "}
                NFTCloud <b>$0.20</b>
              </div>
            </Box>
            <Box padding="12px 0px 12px 0px">
              <Flex justify="center" align="center" wrap="nowrap">
                <Flex
                  marginRight="12px"
                  padding="8px"
                  background="#FFEA97"
                  justify="center"
                  align="center"
                  borderRadius="10px"
                >
                  <Image src={twitter} width="20px" height="20px" />
                </Flex>
                <Flex
                  marginRight="12px"
                  padding="8px"
                  background="#FFEA97"
                  justify="center"
                  align="center"
                  borderRadius="10px"
                >
                  <Image src={telegram} width="20px" height="20px" />
                </Flex>
                <Flex
                  padding="8px"
                  background="#FFEA97"
                  justify="center"
                  align="center"
                  borderRadius="10px"
                >
                  <Image src={discord} width="20px" height="20px" />
                </Flex>
              </Flex>
            </Box>
            <Flex align="center" justify="center">
              <Text
                color="global.dividerColor"
                fontSize="14px"
                fontWeight="600"
                textAlign="center"
              >
                All rights reserved - ©2023 NFTCloud
              </Text>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default MobileMenuModal;
