import React from "react";
import { Box, Stack, Button } from "@chakra-ui/react";
import { TransparentHeader } from "src/components/common/Header";
import mainBackgroundImage from "src/assets/cloud.png";
import PageHeader from "./components/PageHeader";
import Pool from "./components/Pool";
import { isMobileScreen } from "src/utils/responsive";
import pools from "./pools.json";
import { useFetchStake } from "src/hooks/stakeToken/useFetchStake";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useWalletModal } from "src/components/common/WalletModal";
import useAuth from "src/hooks/useAuth";

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

function StakePage() {
  const isMobile = isMobileScreen();

  const [loading, setLoading] = useState(true);
  const [reloadTime, setReloadTime] = useState(0);
  const poolsInfo = useFetchStake(setLoading, reloadTime);
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(
    login,
    logout,
    account as any
  );

  return (
    <Box
      height="100%"
      width="auto"
      style={{
        backgroundImage: `url("${mainBackgroundImage}"), linear-gradient(180deg, #181F51 0%, #111745 20.31%, #0C103A 43.23%, #070A2E 66.67%, #040726 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundAttachment: "fixed",
        backgroundPosition: "50% 100%",
      }}
      overflowX="hidden"
    >
      <TransparentHeader isFixed isDark />
      <Stack
        paddingTop="130px"
        paddingX="30px"
        height="100%"
        direction="column"
        color="white"
        alignItems="center"
      >
        {!account ? (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              color: "#fff",
              fontSize: "20px",
              marginTop: "50px",
            }}
          >
            <div>Please unlock your wallet!</div>
            <div style={{ marginTop: "10px" }}>
              <Button
                {...activeButtonStyle}
                style={{ width: "auto" }}
                onClick={onPresentConnectModal}
              >
                Connect Wallet
              </Button>
            </div>
          </div>
        ) : loading ? (
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border text-light" role="status"></div>
          </div>
        ) : (
          <Stack
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems={isMobile ? "center" : "flex-end"}
            direction={isMobile ? "column" : "row"}
            gap="20px"
          >
            <PageHeader />
            <div
              style={{
                flexGrow: 1,
                margin: "0",
                height: "100%",
              }}
            >
              <Stack
                display="flex"
                flexWrap="wrap"
                // width={["fit-content", "100%", "80%"]}
                maxWidth="100%"
                margin="0 auto 20px"
                gap={isMobile ? "20px" : "60px"}
                direction={["column", "column", "row"]}
                justifyContent="center"
              >
                {pools.map((pool) => (
                  <Pool
                    id={pool.poolId}
                    key={pool.poolId}
                    staked={poolsInfo[pool.poolId].staked}
                    earned={poolsInfo[pool.poolId].earned}
                    setReloadTime={setReloadTime}
                  />
                ))}
              </Stack>
            </div>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default StakePage;
