import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { harvestAll } from "../utils/callHelpers";
import { getStakingContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useHarvestAll = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);

  const handleHarvestAll = useCallback(async () => {
    const txHash = await harvestAll(account, contract);
    return txHash;
  }, [account, contract]);

  return { onHarvestAll: handleHarvestAll };
};
