import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { approve } from "../utils/callHelpers";
import { getBep20Contract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import { useAppDispatch } from "../state";

export const useApprove = (tokenAddress) => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getBep20Contract(tokenAddress, web3);

  const handleApprove = useCallback(
    async (spender) => {
      const txHash = await approve(account, contract, spender);
      return txHash;
    },
    [account, contract]
  );

  return { onApprove: handleApprove };
};
