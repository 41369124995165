import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
import getRpcUrl from './getRpcUrl'

const RPC_URL = getRpcUrl() || ""
const ARCHIVED_NODE = process.env.REACT_APP_ARCHIVED_NODE || "";

const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions)
const web3NoAccount = new Web3(httpProvider)

const archivedHttpProvider = new Web3.providers.HttpProvider(ARCHIVED_NODE, { timeout: 10000 } as HttpProviderOptions)
export const web3WithArchivedNodeProvider = new Web3(archivedHttpProvider)

export default web3NoAccount
