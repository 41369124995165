import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useBuyNFT } from "src/hooks/useBuyNFT";
import { useApprove } from "src/hooks/useApprove";
import { CLOUD_TOKEN, SALE_NFT_CONTRACT } from "src/config";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  title: string;
  disableAction: boolean;
  quantity: number;
  nftType: any;
  allowance: any;
}

function CustomModal(props: Props) {
  const {
    children,
    isOpen,
    onClose,
    title,
    disableAction,
    nftType,
    quantity,
    allowance,
  } = props;
  const { onBuyNFT } = useBuyNFT();
  const { onApprove } = useApprove(CLOUD_TOKEN);
  const [requestedApproval, setRequestedApproval] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleBuyNFT = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onBuyNFT(nftType, quantity);
      onClose();
      toast.success("Successfully buy your NFT!", { autoClose: 3000 });
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      console.error(e);
    }
  }, [onBuyNFT]);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove(SALE_NFT_CONTRACT);
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      console.error(e);
    }
  }, [onApprove]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay
        background="rgba(24, 31, 81, 0.8)"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalHeader
          fontWeight={700}
          fontSize="24px"
          lineHeight="33px"
          borderBottom="1px solid #EEEEEE"
          display="flex"
          alignItems="center"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color="#FFB524" top="16px" />
        <ModalBody borderBottom="1px solid #EEEEEE">{children}</ModalBody>

        <ModalFooter>
          {!requestedApproval ? (
            allowance > 0 ? (
              <Button
                background="#FFB524"
                w="100%"
                borderRadius="8px"
                onClick={handleBuyNFT}
                fontWeight={700}
                fontSize={16}
                line-height="140%"
                disabled={disableAction}
              >
                Buy
              </Button>
            ) : (
              <Button
                background="#FFB524"
                w="100%"
                borderRadius="8px"
                onClick={handleApprove}
                fontWeight={700}
                fontSize={16}
                line-height="140%"
              >
                Approve
              </Button>
            )
          ) : (
            <Button
              background="#EDF2F7 !important"
              w="100%"
              borderRadius="8px"
              fontWeight={700}
              fontSize={16}
              line-height="140%"
              cursor="default"
            >
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CustomModal;
