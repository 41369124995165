/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import {
  Input,
  Text,
  HStack,
  InputGroup,
  InputLeftElement,
  Icon,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useHarvestAll } from "src/hooks/useHarvestAll";
import { useFetchStake } from "src/hooks/useFetchStake";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import { useWeb3React } from "@web3-react/core";
import useAuth from "src/hooks/useAuth";
import { useWalletModal } from "src/components/common/WalletModal";
import { useRewardTokenPrice } from "src/hooks/useRewardTokenPrice";

import ClaimItem from "./ClaimItem";

function SearchIcon() {
  return (
    <Icon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2716 11.6684L10.3313 8.72812C11.0391 7.78573 11.4213 6.63865 11.42 5.46C11.42 2.44938 8.97062 0 5.96 0C2.94938 0 0.5 2.44938 0.5 5.46C0.5 8.47062 2.94938 10.92 5.96 10.92C7.13865 10.9213 8.28573 10.5391 9.22812 9.83125L12.1684 12.7716C12.3173 12.9046 12.5114 12.9756 12.711 12.97C12.9105 12.9645 13.1004 12.8827 13.2415 12.7415C13.3827 12.6004 13.4645 12.4105 13.47 12.211C13.4756 12.0114 13.4046 11.8173 13.2716 11.6684ZM2.06 5.46C2.06 4.68865 2.28873 3.93463 2.71727 3.29328C3.14581 2.65192 3.7549 2.15205 4.46753 1.85687C5.18017 1.56169 5.96433 1.48446 6.72085 1.63494C7.47738 1.78542 8.17229 2.15686 8.71772 2.70228C9.26314 3.24771 9.63458 3.94262 9.78506 4.69915C9.93554 5.45567 9.85831 6.23983 9.56313 6.95247C9.26795 7.6651 8.76808 8.27419 8.12672 8.70273C7.48537 9.13127 6.73135 9.36 5.96 9.36C4.92604 9.35876 3.93478 8.94747 3.20365 8.21635C2.47253 7.48522 2.06124 6.49396 2.06 5.46Z"
          fill="#181F51"
        />
      </svg>
    </Icon>
  );
}

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

export default function ClaimTab(props) {
  const [loading, setLoading] = useState(true);
  const [claimAllInProcess, setClaimAllInProcess] = useState(false);
  const { stakingInfo } = props;

  const { onHarvestAll } = useHarvestAll();
  const staked = useFetchStake(setLoading, 0);
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(
    login,
    logout,
    account as any
  );
  const cloudTokenPrice = useRewardTokenPrice();

  return (
    <VStack w="100%">
      <HStack flexDirection="row" w="100%" justifyContent="space-between">
        <HStack w={["100%", "50%", "50%"]}>
          <InputGroup w={["70%", "50%", "50%"]}>
            <InputLeftElement children={<SearchIcon />} />
            <Input placeholder="Search Staked ID" />
          </InputGroup>
          <InputGroup w={["30%", "80px", "80px"]}>
            <Input
              placeholder="Latest"
              _placeholder={{ opacity: 1, color: "#181F51" }}
            />
          </InputGroup>
        </HStack>
        <HStack display={["none", "none", "flex"]}>
          <HStack gap="32px">
            <VStack>
              <Text
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="24px"
                lineHeight="36px"
                color="#181F51"
              >
                {stakingInfo.totalNftLocked}
              </Text>
              <Text
                color="#181F51"
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="14px"
                lineHeight="21px"
                align="center"
              >
                Total NFT locked
              </Text>
            </VStack>
            <VStack>
              <Text
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="24px"
                lineHeight="36px"
                color="#181F51"
              >
                <>
                  {getFullDisplayBalance(stakingInfo.totalValueLocked, 18, 2)}{" "}
                  USDT
                </>
              </Text>
              <Text
                color="#181F51"
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="14px"
                lineHeight="21px"
                align="center"
              >
                Total value locked
              </Text>
            </VStack>
            <VStack>
              <Text
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="24px"
                lineHeight="36px"
                color="#181F51"
              >
                <>
                  {(
                    (stakingInfo.totalEarn * cloudTokenPrice) /
                    1e8 /
                    1e18
                  ).toFixed(2)}{" "}
                  USDT{" "}
                  <small>
                    (~ {getFullDisplayBalance(stakingInfo.totalEarn, 18, 0)}{" "}
                    CLOUD)
                  </small>
                </>
              </Text>
              <Text
                color="#181F51"
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="14px"
                lineHeight="21px"
                align="center"
              >
                Total earned
              </Text>
            </VStack>
            <Button
              m="0 !important"
              {...activeButtonStyle}
              disabled={claimAllInProcess}
              onClick={async () => {
                setClaimAllInProcess(true);
                try {
                  await onHarvestAll();
                  setClaimAllInProcess(false);
                } catch (e) {
                  setClaimAllInProcess(false);
                }
              }}
            >
              {claimAllInProcess ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                  style={{ color: "#181F51" }}
                ></div>
              ) : (
                <>Claim</>
              )}
            </Button>
          </HStack>
        </HStack>
      </HStack>
      <VStack w="100%" mt="30px !important" gap="20px">
        {staked.map((item) => (
          <ClaimItem
            item={item}
            key={item.id}
            cloudTokenPrice={cloudTokenPrice}
          />
        ))}

        {!account ? (
          <div
            style={{
              height: "200px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "21px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: "#181F51",
              flex: "none",
            }}
          >
            <div>
              <div>Please unlock wallet to claim reward.</div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  {...activeButtonStyle}
                  style={{ width: "auto" }}
                  onClick={onPresentConnectModal}
                >
                  Connect Wallet
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {loading && (
              <div
                style={{
                  textAlign: "center",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className="spinner-border text-light"
                  role="status"
                  style={{ color: "#181F51" }}
                ></div>
              </div>
            )}
            {!loading && staked.length === 0 && (
              <div
                style={{
                  height: "200px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#181F51",
                  opacity: "0.4",
                  flex: "none",
                }}
              >
                Looks like you have not staked anything here. Chop chop! Get
                some!
              </div>
            )}
          </>
        )}
      </VStack>
    </VStack>
  );
}
