import Web3 from "web3";
// @ts-ignore
import Cookies from "universal-cookie";

const cookies = new Cookies();

const useReferrer = () => {
  const query = window.location.search.substring(1);
  if (query.length > 0) {
    let referralCode = query.split("team=")[1];
    cookies.set("referal_code", referralCode, { path: "/" });
  }
  const ref = cookies.get("referal_code");

  if (ref && ref !== "null") {
    if (Web3.utils.isAddress(ref)) {
      return {
        ref: Web3.utils.toChecksumAddress(ref),
      };
    }
  }
  return {
    ref: "0x0000000000000000000000000000000000000000",
  };
};
export default useReferrer;
