import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Radio,
  RadioGroup,
  VStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import search from "src/assets/search.png";
import filter from "src/assets/filter.png";
import yellowPiggy from "src/assets/mdi_piggy-bank.png";
import whitePiggy from "src/assets/white_piggy-bank.png";
import funnel from "src/assets/funnel.png";

const styles = {
  title: {
    color: "global.dividerColor",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "500",
    marginBottom: "10px",
    display: {
      xl: "block",
      base: "none",
    },
  },
  formInputText: {
    width: "176px",
    height: "32px",
    padding: "5.5px 17.5px",
    fontSize: "14px",
    color: "global.dividerColor",
    borderRadius: "8px",
    background: {
      xl: "rgba(24, 31, 81, 0.05)",
      base: "linear-gradient(0deg, rgba(255, 181, 36, 0.05), rgba(255, 181, 36, 0.05)), #FFFFFF",
    },
    border: "1px solid rgba(24, 31, 81, 0.2)",
  },
  square: {
    width: "8px",
    height: "8px",
    transform: "rotate(45deg)",
  },
  buttonFilterStyle: {
    height: "37px",
    background: {
      xl: "rgba(24, 31, 81, 0.05)",
      base: "linear-gradient(0deg, rgba(255, 181, 36, 0.05), rgba(255, 181, 36, 0.05)), #FFFFFF",
    },
    width: "100%",
    minWidth: "160px",
    borderRadius: "8px",
    border: "1px solid rgba(24, 31, 81, 0.2)",
  },
};

const rankOptions = [
  {
    label: "All",
    value: "All",
    color: "global.dividerColor",
  },
  {
    label: "Common",
    value: "Common",
    color: "#938FF2",
  },
  {
    label: "Rare",
    value: "Rare",
    color: "#01B976",
  },
  {
    label: "Legendary",
    value: "Legendary",
    color: "#FFA800",
  },
];

const collectionOptions = [
  {
    label: "All",
    value: "All",
    color: "global.dividerColor",
  },
  {
    label: "Normal",
    value: "Normal",
    color: "#938FF2",
  },
  {
    label: "V.I.P",
    value: "V.I.P",
    color: "#FFA800",
  },
];

interface ISiderbar {
  isFixedFilter: boolean;
  setNftStatusFilter: any;
  setNftRankFilter: any;
  setNftCollectionFilter: any;
}

function Sidebar({
  isFixedFilter,
  setNftStatusFilter,
  setNftRankFilter,
  setNftCollectionFilter,
}: ISiderbar) {
  return (
    <Box>
      <FormControl marginBottom="20px">
        <FormLabel {...styles.title}>Search items</FormLabel>
        <Box width="100%" height="32px" position="relative">
          <Image
            src={search}
            alt="search"
            width="16px"
            height="16px"
            position="absolute"
            left="16px"
            top="50%"
            transform="translateY(-50%)"
            zIndex="2"
          />
          <Input
            {...styles.formInputText}
            placeholder="Search Staked ID"
            variant="unstyled"
            paddingLeft="41.5px"
            width="100%"
          />
        </Box>
        <FormErrorMessage>123</FormErrorMessage>
      </FormControl>
      <Box
        display={{
          xl: "block",
          base: "none",
        }}
      >
        <FormControl marginBottom="46px">
          <FormLabel {...styles.title}>Status</FormLabel>
          <RadioGroup defaultValue="All" onChange={setNftStatusFilter}>
            <VStack justify="center" align="start">
              <Radio value="All">
                <Flex
                  marginLeft="10px"
                  width="133px"
                  height="40px"
                  backgroundColor="global.greyText"
                  color="#FFFFFF"
                  align="center"
                  justify="left"
                  borderRadius="12px"
                  padding="12px 16px"
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  <Image
                    src={whitePiggy}
                    alt="pig yellow"
                    width="16px"
                    height="16px"
                    marginRight="8px"
                  />
                  All
                </Flex>
              </Radio>
              <Radio value="In Wallet">
                <Flex
                  marginLeft="10px"
                  width="133px"
                  height="40px"
                  backgroundColor="global.dividerColor"
                  color="global.yellowOrange"
                  align="center"
                  justify="left"
                  borderRadius="12px"
                  padding="12px 16px"
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  <Image
                    src={yellowPiggy}
                    alt="pig yellow"
                    width="16px"
                    height="16px"
                    marginRight="8px"
                  />
                  In Wallet
                </Flex>
              </Radio>
              <Radio value="Staked">
                <Flex
                  marginLeft="10px"
                  width="133px"
                  height="40px"
                  backgroundColor="global.pink"
                  color="#FFFFFF"
                  align="center"
                  justify="left"
                  borderRadius="12px"
                  padding="12px 16px"
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                  fontStyle="normal"
                >
                  <Image
                    src={whitePiggy}
                    alt="pig white"
                    width="16px"
                    height="16px"
                    marginRight="8px"
                  />
                  Staked
                </Flex>
              </Radio>
            </VStack>
          </RadioGroup>
          <FormErrorMessage>123</FormErrorMessage>
        </FormControl>
        <FormControl marginBottom="46px">
          <FormLabel {...styles.title}>Rank</FormLabel>
          <RadioGroup defaultValue="All" onChange={setNftRankFilter}>
            <VStack justify="center" align="start">
              {rankOptions &&
                rankOptions.map((rankOption) => {
                  return (
                    <Radio value={rankOption?.value} key={rankOption?.value}>
                      <Flex
                        marginLeft="10px"
                        width="133px"
                        height="40px"
                        align="center"
                        justify="start"
                        borderRadius="12px"
                        fontSize="16px"
                        lineHeight="24px"
                        fontWeight="500"
                        color={rankOption?.color}
                      >
                        <Box
                          {...styles.square}
                          marginRight="8px"
                          backgroundColor={rankOption?.color}
                        />
                        {rankOption?.label}
                      </Flex>
                    </Radio>
                  );
                })}
            </VStack>
          </RadioGroup>
          <FormErrorMessage>123</FormErrorMessage>
        </FormControl>
        <FormControl marginBottom="46px">
          <FormLabel {...styles.title}>Collection</FormLabel>
          <RadioGroup defaultValue="All" onChange={setNftCollectionFilter}>
            <VStack justify="center" align="start">
              {collectionOptions &&
                collectionOptions.map((collectionOption) => {
                  return (
                    <Radio
                      value={collectionOption?.value}
                      key={collectionOption?.value}
                    >
                      <Flex
                        marginLeft="10px"
                        width="133px"
                        height="40px"
                        align="center"
                        justify="start"
                        borderRadius="12px"
                        fontSize="16px"
                        lineHeight="24px"
                        fontWeight="500"
                        color={collectionOption?.color}
                      >
                        <Box
                          {...styles.square}
                          marginRight="8px"
                          backgroundColor={collectionOption?.color}
                        />
                        {collectionOption?.label}
                      </Flex>
                    </Radio>
                  );
                })}
            </VStack>
          </RadioGroup>
          <FormErrorMessage>123</FormErrorMessage>
        </FormControl>
      </Box>
      <Flex
        justify="center"
        alignItems="center"
        display={{
          xl: "none",
          base: "flex",
        }}
        {...(isFixedFilter
          ? {
              position: {
                xl: "unset",
                base: "fixed",
              },
              bottom: "64px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
              borderRadius: "12px",
              padding: "12px",
              background: "white",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "999",
            }
          : {})}
      >
        <Button
          variant="unstyled"
          {...styles.buttonFilterStyle}
          textAlign="left"
          paddingLeft="41.5px"
          marginRight="8px"
        >
          <Image
            src={filter}
            alt="filter"
            width="16px"
            height="16px"
            position="absolute"
            left="16px"
            top="50%"
            transform="translateY(-50%)"
            zIndex="2"
          />
          <Text>Filter</Text>
        </Button>
        <Button
          variant="unstyled"
          {...styles.buttonFilterStyle}
          textAlign="left"
          paddingLeft="41.5px"
        >
          <Image
            src={funnel}
            alt="latest"
            width="16px"
            height="16px"
            position="absolute"
            left="16px"
            top="50%"
            transform="translateY(-50%)"
            zIndex="2"
          />
          <Text>Latest</Text>
        </Button>
      </Flex>
    </Box>
  );
}

export default Sidebar;
