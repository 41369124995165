/* eslint-disable react/require-default-props */

import {
  Box,
  Button,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import expand from "src/assets/expand.png";
import ImageDetails from "./ImageDetails";
import NFTDetails from "./NFTDetails";

// eslint-disable-next-line no-unused-vars
enum EnumStep {
  // eslint-disable-next-line no-unused-vars
  Attributes = "Attributes",
  // eslint-disable-next-line no-unused-vars
  InstantSell = "Instant Sell",
  // eslint-disable-next-line no-unused-vars
  Stake = "Stake",
}

interface INFTDetailsModal {
  isOpen: boolean;
  onClose: () => void;
  selectedStep?: EnumStep | null;
  item: any;
}

const Step: Record<string, string> = {
  Attributes: "Attributes",
  InstantSell: "Instant Sell",
  Stake: "Stake",
};

function NFTDetailsModal(props: INFTDetailsModal) {
  const { isOpen, onClose, selectedStep, item } = props;
  const [step, setStep] = React.useState(selectedStep || Step.Attributes);
  const [isShowFullScreen, setIsShowFullScreen] = React.useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width={{
          xl: "1140px",
          base: "100%",
        }}
        maxWidth="none"
        borderRadius="20px"
        overflow="hidden"
        position="relative"
        marginTop={{
          xl: "4rem",
          base: "0px",
        }}
        marginBottom={{
          xl: "4rem",
          base: "0px",
        }}
        background={{
          xl: "unset",
          base: "linear-gradient(180deg, #FF6F80 0%, #FFDB6A 59.37%, #FFCE4A 76.56%, #FFC133 87.5%, #FFA800 100%, #FFB320 100%)",
        }}
      >
        <Button
          variant="unstyled"
          position="absolute"
          right="5px"
          top="5px"
          width="32px"
          height="32px"
          boxShadow="none !important"
          onClick={() => {
            setIsShowFullScreen((curr) => {
              return !curr;
            });
          }}
        >
          <Image src={expand} alt="expand" width="32px" height="32px" />
        </Button>
        <ModalBody width="100%" padding="0" height="100%">
          <Grid
            width="100%"
            height="100%"
            templateColumns={{
              xl: isShowFullScreen ? "1fr" : "1fr 1fr",
              base: "1fr",
            }}
            gap="0px"
          >
            <Box
              width="100%"
              height="100%"
              display={isShowFullScreen ? "none" : "block"}
              order={{
                xl: "unset",
                base: 2,
              }}
              padding={{
                xl: "unset",
                base: "117px 24px 133px 24px",
              }}
            >
              <NFTDetails
                step={step}
                setStep={setStep}
                onClose={onClose}
                item={item}
              />
            </Box>
            <ImageDetails
              containerProps={{
                width: "100%",
                order: {
                  xl: "unset",
                  base: "1",
                },
              }}
              tokenURI={item.tokenURI}
              isVideo={item.collection === "V.I.P"}
            />
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default NFTDetailsModal;
