import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { harvest } from "../utils/callHelpers";
import { getStakingContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useHarvest = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);

  const handleHarvest = useCallback(
    async (packageId) => {
      const txHash = await harvest(account, contract, packageId);
      return txHash;
    },
    [account, contract]
  );

  return { onHarvest: handleHarvest };
};
