import React from "react";
import {
  CardHeader,
  Card,
  CardBody,
  Text,
  Image,
  Stack,
} from "@chakra-ui/react";

export default function Item(props) {
  const { item } = props;
  return (
    <Card maxW="sm">
      <CardHeader padding={0}>
        {item.collection === "Normal" && (
          <Image src={item.tokenURI} borderTopRadius="4" />
        )}
        {item.collection === "V.I.P" && (
          <video src={item.tokenURI} autoPlay={false} />
        )}
      </CardHeader>
      <CardBody padding="10px">
        <Stack>
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="12px"
            lineHeight="18px"
            textAlign="center"
          >
            #{item.tokenId}
          </Text>
        </Stack>
      </CardBody>
    </Card>
  );
}
