import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useApprove } from "src/hooks/useApprove";
import { USDT_TOKEN, SALE_NFT_USDT_CONTRACT } from "src/config";
import { useBuyNFTWithUSDT } from "src/hooks/useBuyNFTWithUSDT";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  title: string;
  disableAction: boolean;
  quantity: number;
  nftType: any;
  allowance: any;
  total: number;
}

function UsdtSaleModal(props: Props) {
  const {
    children,
    isOpen,
    onClose,
    title,
    disableAction,
    nftType,
    quantity,
    allowance,
    total,
  } = props;
  const { onBuyNFT } = useBuyNFTWithUSDT();
  const { onApprove } = useApprove(USDT_TOKEN);
  const [requestedApproval, setRequestedApproval] = useState(false);
  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleBuyNFT = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onBuyNFT(nftType, quantity);
      onClose();
      toast.success("Successfully buy your NFT!", { autoClose: 3000 });
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      console.error(e);
    }
  }, [onBuyNFT]);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove(SALE_NFT_USDT_CONTRACT);
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      console.error(e);
    }
  }, [onApprove]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none">
      <ModalOverlay
        background="rgba(24, 31, 81, 0.8)"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalHeader
          fontWeight={700}
          fontSize="24px"
          lineHeight="33px"
          borderBottom="1px solid #EEEEEE"
          display="flex"
          alignItems="center"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color="#FFB524" top="16px" />
        <ModalBody borderBottom="1px solid #EEEEEE">{children}</ModalBody>

        <ModalFooter>
          {!requestedApproval ? (
            allowance >= total * 1e18 ? (
              <Button
                w="100%"
                borderRadius="8px"
                onClick={() => {
                  if (!disableAction) {
                    handleBuyNFT();
                  }
                }}
                fontWeight={700}
                fontSize={16}
                line-height="140%"
                disabled={disableAction}
                backgroundColor={disableAction ? "#EEEEEE" : "#FFB524"}
                color={disableAction ? "#868686" : "global.dividerColor"}
                cursor={disableAction ? "default" : "cursor"}
              >
                Buy
              </Button>
            ) : (
              <Button
                background="#FFB524"
                w="100%"
                borderRadius="8px"
                onClick={handleApprove}
                fontWeight={700}
                fontSize={16}
                line-height="140%"
              >
                Approve
              </Button>
            )
          ) : (
            <Button
              background="#EDF2F7 !important"
              w="100%"
              borderRadius="8px"
              fontWeight={700}
              fontSize={16}
              line-height="140%"
              cursor="default"
            >
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UsdtSaleModal;
