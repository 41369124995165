/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from "react";
import {
  Input,
  Text,
  HStack,
  InputGroup,
  InputLeftElement,
  Icon,
  Grid,
  GridItem,
  Button,
  Box,
  Stack,
} from "@chakra-ui/react";

import Item from "./Item";
import { useMyItems } from "../../../hooks/useMyItems";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useAuth from "src/hooks/useAuth";
import { useWalletModal } from "src/components/common/WalletModal";

function SearchIcon() {
  return (
    <Icon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2716 11.6684L10.3313 8.72812C11.0391 7.78573 11.4213 6.63865 11.42 5.46C11.42 2.44938 8.97062 0 5.96 0C2.94938 0 0.5 2.44938 0.5 5.46C0.5 8.47062 2.94938 10.92 5.96 10.92C7.13865 10.9213 8.28573 10.5391 9.22812 9.83125L12.1684 12.7716C12.3173 12.9046 12.5114 12.9756 12.711 12.97C12.9105 12.9645 13.1004 12.8827 13.2415 12.7415C13.3827 12.6004 13.4645 12.4105 13.47 12.211C13.4756 12.0114 13.4046 11.8173 13.2716 11.6684ZM2.06 5.46C2.06 4.68865 2.28873 3.93463 2.71727 3.29328C3.14581 2.65192 3.7549 2.15205 4.46753 1.85687C5.18017 1.56169 5.96433 1.48446 6.72085 1.63494C7.47738 1.78542 8.17229 2.15686 8.71772 2.70228C9.26314 3.24771 9.63458 3.94262 9.78506 4.69915C9.93554 5.45567 9.85831 6.23983 9.56313 6.95247C9.26795 7.6651 8.76808 8.27419 8.12672 8.70273C7.48537 9.13127 6.73135 9.36 5.96 9.36C4.92604 9.35876 3.93478 8.94747 3.20365 8.21635C2.47253 7.48522 2.06124 6.49396 2.06 5.46Z"
          fill="#181F51"
        />
      </svg>
    </Icon>
  );
}

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

export default function StakeTab(props) {
  const [loading, setLoading] = useState(true);
  const {
    selectedItems,
    setSelectedItems,
    setItems,
    stakingPool,
    getNftTime,
    handleSwitchRightTab,
    getRightTabStatus,
  } = props;
  const nftItems = useMyItems("All", setLoading, 1, getNftTime);
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(
    login,
    logout,
    account as any
  );

  useEffect(() => {
    const obj = {};
    for (let i = 0; i < nftItems.length; i++) {
      obj[nftItems[i].tokenId] = nftItems[i];
    }
    setItems(obj);
  }, [nftItems]);

  const onItemClick = (tokenId) => {
    if (selectedItems.indexOf(tokenId) === -1) {
      setSelectedItems([...selectedItems, tokenId]);
    } else {
      const temp = selectedItems;
      temp.splice(selectedItems.indexOf(tokenId), 1);
      setSelectedItems(temp);
    }
  };

  const nftItemsToDisplay = nftItems
    .filter((item) => {
      return !item.isStaked;
    })
    .filter((item) => {
      return stakingPool === "Ordinary"
        ? item.collection === "Normal"
        : item.collection === "V.I.P";
    });

  return (
    <>
      <HStack
        w="full"
        justifyContent={["flex-end", "flex-end", "space-between"]}
        mb="20px"
        display={["none", "none", "block"]}
      >
        <Text
          color="#181F51"
          marginRight="24px"
          fontFamily="Poppins, sans-serif"
          fontWeight={600}
          fontSize="14px"
          lineHeight="21px"
          display={["none", "none", "block"]}
        >
          Choose your assets
        </Text>
      </HStack>

      <Stack display={["block", "block", "none"]}>
        <div>
          <Text
            color="#181F51"
            marginRight="24px"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="16px"
            lineHeight="140%"
            display={["block", "block", "none"]}
            textAlign="center"
            marginBottom="10px"
          >
            Staking Pool
          </Text>
        </div>
        <Box borderRadius="8px" background="rgba(255, 181, 36, 0.4)">
          <Button
            onClick={() => handleSwitchRightTab(2)}
            m="0 !important"
            {...getRightTabStatus(2)}
          >
            Ordinary
          </Button>
          <Button
            onClick={() => handleSwitchRightTab(3)}
            m="0 !important"
            {...getRightTabStatus(3)}
          >
            Supremacy
          </Button>
        </Box>
        <div style={{ height: "20px" }} />
      </Stack>

      {account ? (
        loading ? (
          <div
            style={{
              textAlign: "center",
              height: "200px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="spinner-border text-light"
              role="status"
              style={{ color: "#181F51" }}
            ></div>
          </div>
        ) : (
          <>
            <Grid
              w="100%"
              templateColumns={[
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
              ]}
              gap={6}
            >
              {nftItemsToDisplay.map((item) => (
                <GridItem w="100%" key={item.tokenId}>
                  <Item
                    onItemClick={onItemClick}
                    item={item}
                    tokenId={item.tokenId}
                    price={item.price}
                    selected={selectedItems.indexOf(item.tokenId) !== -1}
                  />
                </GridItem>
              ))}
            </Grid>
            {nftItemsToDisplay.length === 0 && (
              <div
                style={{
                  height: "200px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#181F51",
                  flex: "none",
                }}
              >
                <div>
                  <div>Looks like you have not NFT anything here.</div>
                  <div style={{ marginTop: "10px" }}>
                    <Link to="/">
                      <Button {...activeButtonStyle}>Buy Now</Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      ) : (
        <div
          style={{
            height: "200px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            color: "#181F51",
            flex: "none",
          }}
        >
          <div>
            <div>Please unlock wallet to stake NFT.</div>
            <div style={{ marginTop: "10px" }}>
              <Button
                {...activeButtonStyle}
                style={{ width: "auto" }}
                onClick={onPresentConnectModal}
              >
                Connect Wallet
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
