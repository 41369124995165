import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { stakeCloud } from "../../utils/callHelpers";
import { getCloudStakingContract } from "../../utils/contractHelpers";
import useWeb3 from "../useWeb3";

export const useHarvest = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getCloudStakingContract(web3);

  const handleHarvest = useCallback(
    async (pid) => {
      const txHash = await stakeCloud(account, contract, pid, 0);
      return txHash;
    },
    [account, contract]
  );

  return { onHarvest: handleHarvest };
};
