import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "../useRefresh";
import useWeb3 from "../../hooks/useWeb3";
import { getCloudStakingContract } from "src/utils/contractHelpers";

const BIG_ZERO = new BigNumber(0);

export const useGetFreeInvestmentAmount = (pid) => {
  const [freeAmount, setFreeAmount] = useState(BIG_ZERO);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getCloudStakingContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchFreeAmount = async () => {
      const res = await contract.methods
        .getFreeInvestmentAmount(pid, account)
        .call();
      setFreeAmount(new BigNumber(res));
    };
    if (account) {
      fetchFreeAmount();
    }
  }, [account, contract, fastRefresh]);

  return freeAmount;
};
