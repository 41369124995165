import React from "react";
import ReactDOM from "react-dom/client";
import { Web3ReactProvider } from "@web3-react/core";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
// import reportWebVitals from './reportWebVitals';
import { getLibrary } from "./utils/web3React";
import store from "./state";
window.Buffer = require("buffer/").Buffer;

const rootDOM = document.getElementById("root");
if (rootDOM) {
  const root = ReactDOM.createRoot(rootDOM);
  root.render(
    // <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <App />
      </Provider>
      <ToastContainer />
    </Web3ReactProvider>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
