import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getStakingContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import BigNumber from "bignumber.js";
import useRefresh from "./useRefresh";

export const useFetchPoolInfo = () => {
  const [totalNftLocked, setTotalNftLocked] = useState(0);
  const [totalValueLocked, setTotalValueLocked] = useState(new BigNumber(0));
  const [totalEarn, setTotalEarn] = useState(new BigNumber(0));

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchPoolInfo = async () => {
      const poolInfo = await contract.methods.getPoolInfo().call();

      setTotalNftLocked(poolInfo.totalNftLocked);
      setTotalValueLocked(new BigNumber(poolInfo.totalAmount));
      setTotalEarn(new BigNumber(poolInfo.totalEarned));
    };
    if (account) {
      fetchPoolInfo();
    }
  }, [account, web3, fastRefresh]);

  return {
    totalNftLocked,
    totalValueLocked,
    totalEarn,
  };
};
