import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { stake } from "../utils/callHelpers";
import useReferrer from "./useReferer";
import { getStakingContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useStake = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);

  const { ref } = useReferrer();
  const handleStake = useCallback(
    async (ids) => {
      const txHash = await stake(account, contract, ids, ref);
      return txHash;
    },
    [account, contract]
  );

  return { onStake: handleStake };
};
