import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import pricetags from "src/assets/pricetags.png";
import pricetagsDisabled from "src/assets/pricetags-disabled.png";
import yellowPiggy from "src/assets/mdi_piggy-bank.png";
import whitePiggy from "src/assets/white_piggy-bank.png";
import { styles } from "./styles";
import { toast } from "react-toastify";
import { getFullDisplayBalance } from "src/utils/formatBalance";

interface INFTItems {
  items: {
    name: string;
    isStaked: boolean;
    tokenURI: string;
    tokenId: any;
    rank: string;
    collection: string;
    price: any;
    subId: any;
  };
  onClick: () => void;
  onClickImage: () => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedStep: (v: string) => void;
  onClickSellButton: () => void;
}

function getTotalItem(items) {
  if (items.collection === "Normal")
    return {
      Common: 6000, //4000
      Rare: 3000,
      Legendary: 2000,
    }[items.rank];
  return {
    Common: 1550, //550
    Rare: 600, //300
    Legendary: 150,
  }[items.rank];
}

function NFTItems(props: INFTItems) {
  const { items, onClick, onClickImage, setSelectedStep, onClickSellButton } =
    props;

  const totalItem = getTotalItem(items);

  return (
    <Card
      borderRadius="20px"
      boxShadow="0px 4px 8px rgba(24, 31, 81, 0.2)"
      marginRight={{
        xl: "60px",
        base: "0px",
      }}
      marginBottom={{
        xl: "50px",
        base: "12px",
      }}
      width={{
        xl: "300px",
        lg: "300px",
        md: "300px",
        base: "100%",
      }}
      maxWidth="300px"
    >
      <CardBody padding="20px" onClick={onClick}>
        <Box
          background="linear-gradient(180deg, #FFEA97 0%, #FFDB6A 59.37%, #FFCE4A 76.56%, #FFC133 87.5%, #FFA800 100%, #FFB320 100%)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="260px"
          width="260px"
          position="relative"
          borderRadius="10px"
          overflow="hidden"
          marginBottom="20px"
          onClick={(e) => {
            e.stopPropagation();
            onClickImage();
          }}
        >
          <div
            style={{ textAlign: "center", position: "absolute", zIndex: 10 }}
          >
            <div className="spinner-border text-light" role="status"></div>
          </div>

          {items.collection !== "V.I.P" && (
            <Image
              src={items.tokenURI}
              alt=""
              width="100%"
              // width={{
              //   xl: "192px",
              //   base: "166px",
              // }}
              // height="215px"
              style={{ zIndex: 9999 }}
            />
          )}

          {items.collection === "V.I.P" && (
            <video
              src={items.tokenURI}
              width="100%"
              height="215px"
              autoPlay
              loop
              style={{ zIndex: 9999 }}
            />
          )}

          {/* {items.isStaked && (
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              height="37px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor="global.dividerColor"
            >
              <Text color="global.yellowOrange" fontWeight="500">
                Ends in 12 day
              </Text>
            </Box>
          )} */}
        </Box>
        <Box padding="0px 10px" marginBottom="20px">
          <Text
            fontFamily="Clash Display"
            color="global.dividerColor"
            fontSize="26px"
            lineHeight="32px"
            fontWeight="600"
          >
            {items.name}{" "}
            <span
              style={{
                display: "inline-block",
                marginLeft: "10px",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
                lineHeight: "39px",
                color: "#FFA800",
              }}
            >
              #{items.tokenId}
            </span>
          </Text>
        </Box>
        <Flex
          padding="0px 10px"
          align="start"
          justify="space-between"
          marginBottom="20px"
        >
          <Box width="50%" maxWidth="50%">
            <Text
              color={
                { Common: "#938FF2", Rare: "#01B976", Legendary: "#FFA800" }[
                  items.rank
                ]
              }
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
            >
              {items.rank}{" "}
              {items.collection === "V.I.P" && (
                <span style={{ fontSize: "10px", color: "#ff4e63" }}>
                  (V.I.P)
                </span>
              )}
            </Text>
            <Text
              color="global.dividerColor"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
            >
              {items.subId} / {totalItem}
            </Text>
          </Box>
          <Box textAlign="right" width="50%" maxWidth="50%">
            <Text
              color="global.dividerColor"
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
            >
              Price
            </Text>
            <Text
              color="global.dividerColor"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
            >
              {getFullDisplayBalance(items.price)} USDT
            </Text>
          </Box>
        </Flex>
        <Flex align="center" justify="space-between">
          <Button
            variant="unstyled"
            border="1px solid #181F51"
            backgroundColor={items.isStaked ? "#ccc" : "global.yellowOrange"}
            style={{
              borderColor: items.isStaked ? "#ccc" : "global.dividerColor",
              cursor: items.isStaked ? "default" : "pointer",
            }}
            {...styles.defaultButton}
            onClick={(e) => {
              e.stopPropagation();
              if (items.isStaked) return;
              // toast.warn("Instant sell is not available!");
              onClickSellButton();
            }}
            disabled={items.isStaked}
          >
            <Image
              src={items.isStaked ? pricetagsDisabled : pricetags}
              alt="pricetags"
              width="14px"
              height="14px"
              marginRight="8px"
            />
            <Text
              fontSize="14px"
              color={items.isStaked ? "#fff" : "global.dividerColor"}
              lineHeight="24px"
              fontWeight="600"
            >
              Instant Sell
            </Text>
          </Button>
          {items.isStaked ? (
            <Button
              variant="unstyled"
              backgroundColor="global.pink"
              {...styles.defaultButton}
              padding="8px 16px"
              color="white"
              style={{ cursor: "default" }}
            >
              <Image
                src={whitePiggy}
                alt="pig white"
                width="14px"
                height="14px"
                marginRight="8px"
              />
              <Text
                fontSize="14px"
                color="white"
                lineHeight="24px"
                fontWeight="600"
              >
                Staked
              </Text>
            </Button>
          ) : (
            <Button
              variant="unstyled"
              {...styles.defaultButton}
              padding="8px 16px"
              backgroundColor="global.dividerColor"
              color="global.yellowOrange"
              onClick={() => {
                setSelectedStep("Transfer");
              }}
            >
              <Image
                src={yellowPiggy}
                alt="pig white"
                width="14px"
                height="14px"
                marginRight="8px"
              />
              <Text
                fontSize="14px"
                color="white"
                lineHeight="24px"
                fontWeight="600"
              >
                Transfer
              </Text>
            </Button>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
}

export default NFTItems;
