import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

type CustomButtonProps = {
  children: React.ReactNode;
} & ButtonProps;

function OrangeButton(props: CustomButtonProps) {
  const { children, ...rest } = props;
  return (
    <Button
      variant="unstyled"
      backgroundColor="global.yellowOrange"
      color="global.dividerColor"
      borderRadius="8px"
      {...rest}
    >
      {children}
    </Button>
  );
}

export default OrangeButton;
