import { Box, Flex, FlexProps, Image } from "@chakra-ui/react";
import React from "react";

interface IImageDetails {
  // eslint-disable-next-line react/require-default-props
  containerProps?: FlexProps;
  tokenURI: string;
  isVideo: boolean;
}

function ImageDetails(props: IImageDetails) {
  const { containerProps, tokenURI, isVideo } = props;

  return (
    <Box
      background={{
        xl: "linear-gradient(180deg, #FF6F80 0%, #FFDB6A 59.37%, #FFCE4A 76.56%, #FFC133 87.5%, #FFA800 100%, #FFB320 100%)",
        base: "unset",
      }}
      boxShadow={{
        xl: "0px 4px 10px rgba(24, 31, 81, 0.08)",
        base: "unset",
      }}
      {...containerProps}
    >
      <Flex
        // border='10px solid rgba(24, 31, 81, 0.08)'
        justify="center"
        align="center"
        height="100%"
        width="100%"
      >
        <div style={{ textAlign: "center", position: "absolute", zIndex: 10 }}>
          <div className="spinner-border text-light" role="status"></div>
        </div>

        {!isVideo && (
          <Image
            src={tokenURI}
            alt=""
            width="418.4px"
            height="538.3px"
            style={{ zIndex: 9999 }}
          />
        )}
        {isVideo && (
          <video
            src={tokenURI}
            autoPlay
            loop
            width="418.4px"
            height="538.3px"
            style={{ zIndex: 9999 }}
          />
        )}
      </Flex>
    </Box>
  );
}

export default ImageDetails;
