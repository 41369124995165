import {
  DEFAULT_GAS_PRICE,
  TESTNET_CHAIN_ID,
  SALE_NFT_CONTRACT,
  RECORD_REFERRAL_CONTRACT,
  REFERRAL_CONTRACT,
  NFT_CONTRACT,
  MULTICALL_CONTRACT,
  STAKING_NFT_CONTRACT,
  SELL_NFT_CONTRACT,
  SALE_OFF_NFT_CONTRACT,
  STAKING_TOKEN_CONTRACT,
  SALE_NFT_USDT_CONTRACT,
} from "../config";
import web3NoAccount from "../utils/web3";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import BigNumber from "bignumber.js";

import buyNFTAbi from "../config/abi/buyNFT.json";
import sellNFTAbi from "../config/abi/sellNFT.json";
import nftAbi from "../config/abi/nft.json";
import bep20Abi from "../config/abi/erc20.json";
import recordReferralAbi from "../config/abi/recordReferral.json";
import referralAbi from "../config/abi/referral.json";
import multicallAbi from "../config/abi/multicall.json";
import stakingAbi from "../config/abi/staking.json";
import stakingTokenAbi from "../config/abi/stakingToken.json";

interface UserSettings {
  gasPrice: number;
}

const BIG_TEN = new BigNumber(10);
const VERSION = 1.01;

const GAS_SETTINGS = {
  default: DEFAULT_GAS_PRICE,
  fast: 10,
  reallyfast: 15,
};

const getDefaultSettings = (): UserSettings => ({
  gasPrice: GAS_SETTINGS.default,
});

const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals));
};

const getGasPriceInWei = (amountInGwei: number) => {
  return getDecimalAmount(new BigNumber(amountInGwei), 9);
};

const getStorageKey = (account: string) => {
  return `pancakeswap_settings_${account}_${VERSION}`;
};

const getSettings = (account: string): UserSettings => {
  try {
    const settingsFromLs = localStorage.getItem(getStorageKey(account));
    return settingsFromLs ? JSON.parse(settingsFromLs) : getDefaultSettings();
  } catch (error) {
    return getDefaultSettings();
  }
};

export const getDefaultGasPrice = () => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  if (chainId === TESTNET_CHAIN_ID) {
    return 10;
  }
  return DEFAULT_GAS_PRICE;
};

const getContract = (
  abi: any,
  address: string,
  web3?: Web3,
  account?: string
) => {
  const _web3 = web3 ?? web3NoAccount;
  const gasPrice = account
    ? getSettings(account).gasPrice
    : getDefaultGasPrice();

  return new _web3.eth.Contract(abi as unknown as AbiItem, address, {
    gasPrice: getGasPriceInWei(gasPrice).toString(),
  });
};

export const getBuyNFTContract = (web3?: Web3) => {
  return getContract(buyNFTAbi, SALE_NFT_CONTRACT, web3);
};

export const getBuySaleOffNFTContract = (web3?: Web3) => {
  return getContract(buyNFTAbi, SALE_OFF_NFT_CONTRACT, web3);
};

export const getBuyNFTWithUSDTContract = (web3?: Web3) => {
  return getContract(buyNFTAbi, SALE_NFT_USDT_CONTRACT, web3);
};

export const getSellNFTContract = (web3?: Web3) => {
  return getContract(sellNFTAbi, SELL_NFT_CONTRACT, web3);
};

export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3);
};

export const getRecordReferralContract = (web3?: Web3) => {
  return getContract(recordReferralAbi, RECORD_REFERRAL_CONTRACT, web3);
};

export const getReferralContract = (web3?: Web3) => {
  return getContract(referralAbi, REFERRAL_CONTRACT, web3);
};

export const getNFTContract = (web3?: Web3) => {
  return getContract(nftAbi, NFT_CONTRACT, web3);
};

export const getMulticallContract = (web3?: Web3) => {
  return getContract(multicallAbi, MULTICALL_CONTRACT, web3);
};

export const getStakingContract = (web3?: Web3) => {
  return getContract(stakingAbi, STAKING_NFT_CONTRACT, web3);
};

export const getCloudStakingContract = (web3?: Web3) => {
  return getContract(stakingTokenAbi, STAKING_TOKEN_CONTRACT, web3);
};
