import React, { useState } from "react";
import {
  HStack,
  useDisclosure,
  Text,
  Input,
  VStack,
  Flex,
  Button,
} from "@chakra-ui/react";
import CustomModal from "src/components/common/Modal";
import UsdtSaleModal from "src/components/common/Modal/UsdtSaleModal";
import Tag from "./Tag";
import {
  getFullDisplayBalance,
  formatNumber,
} from "../../../utils/formatBalance";
import { useCloudTokenPrice } from "src/hooks/useCloudTokenPrice";
import useTokenBalance from "src/hooks/useTokenBalance";
import { SALE_NFT_USDT_CONTRACT, USDT_TOKEN } from "src/config";
import { useAllowance } from "src/hooks/useAllowance";

export default function ListTags(props) {
  const { balance, allowance } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenUSDTModal,
    onOpen: onOpenUSDTModal,
    onClose: onCloseUSDTModal,
  } = useDisclosure();
  const [collection, setCollection] = useState("Normal");
  const [rank, setRank] = useState("common");
  const [quantity, setQuantity] = useState(0);
  const cloudTokenPrice = useCloudTokenPrice();
  const balanceUSDT = useTokenBalance(USDT_TOKEN).balance;
  const allowanceUSDT = useAllowance(
    USDT_TOKEN,
    SALE_NFT_USDT_CONTRACT
  ).toNumber();

  const nftPriceCloud = (getPrice(collection, rank) * 1e8) / cloudTokenPrice;
  const nftPriceUSD = getPrice(collection, rank);
  const total = nftPriceCloud * quantity;
  const nftType = getNftType(collection, rank);
  const totalUSDT = nftPriceUSD * quantity;

  function getPrice(collection, rank) {
    if (collection === "Normal") {
      return {
        common: 100,
        rare: 500,
        legendary: 1000,
      }[rank];
    }
    return {
      common: 5000,
      rare: 10000,
      legendary: 50000,
    }[rank];
  }

  function getNftType(collection, rank) {
    if (collection === "Normal") {
      return {
        common: 0,
        rare: 1,
        legendary: 2,
      }[rank];
    }
    return {
      common: 3,
      rare: 4,
      legendary: 5,
    }[rank];
  }

  function handleInputChange(event) {
    let number = parseInt(event.target.value);
    if (isNaN(number)) number = 0;
    setQuantity(number);
  }

  function maxQuantity() {
    setQuantity(Math.floor(balance.div(1e18) / nftPriceCloud));
  }

  function maxQuantityUsdt() {
    setQuantity(Math.floor(balanceUSDT.div(1e18).toNumber() / nftPriceUSD));
  }

  return (
    <>
      <HStack
        // mt={[350, 350, 600]}
        gap="20px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        padding="24px"
      >
        <Tag
          name="ordinary"
          text="Buy NFT"
          onOpen={() => {
            setCollection("Normal");
            setRank("common");
            setQuantity(0);
            onOpen();
            // onOpenUSDTModal();
          }}
        />
        <Tag
          name="supremacy"
          text="Buy NFT"
          onOpen={() => {
            setCollection("V.I.P");
            setRank("common");
            setQuantity(0);
            onOpenUSDTModal();
          }}
        />
      </HStack>
      <CustomModal
        title="Buy NFT"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        disableAction={total > balance.div(1e18) || quantity <= 0}
        quantity={quantity}
        nftType={nftType}
        allowance={allowance}
      >
        <Text
          marginBottom="10px"
          fontWeight={500}
          fontSize="14px"
          lineHeight="21px"
          float="right"
        >
          Your Balance: {getFullDisplayBalance(balance, 18, 2)} CLOUD
        </Text>

        <Flex w="100%" justifyContent="space-between" marginBottom="10px">
          <Text
            fontWeight={500}
            fontSize="16px"
            lineHeight="24px"
            style={{ alignItems: "center", display: "flex" }}
          >
            Rank
          </Text>
          <Flex gap={2}>
            <Button
              style={rank === "common" ? { background: "#FFB524" } : {}}
              padding={2}
              onClick={() => {
                setRank("common");
              }}
            >
              Common
            </Button>
            <Button
              style={rank === "rare" ? { background: "#FFB524" } : {}}
              padding={2}
              onClick={() => {
                setRank("rare");
                // onClose();
                // onOpenUSDTModal();
              }}
            >
              Rare
            </Button>
            <Button
              style={rank === "legendary" ? { background: "#FFB524" } : {}}
              padding={2}
              onClick={() => {
                setRank("legendary");
                onClose();
                onOpenUSDTModal();
              }}
            >
              Legendary
            </Button>
          </Flex>
        </Flex>

        <Flex w="100%" justifyContent="space-between" marginBottom="10px">
          <Text
            fontWeight={500}
            fontSize="16px"
            lineHeight="24px"
            style={{ alignItems: "center", display: "flex" }}
          >
            Quantity
          </Text>
          <Input
            marginLeft="30px"
            type="number"
            value={quantity}
            onChange={handleInputChange}
          />
          <Button marginLeft="10px" onClick={maxQuantity}>
            Max
          </Button>
        </Flex>

        <VStack
          border="1px solid #F3F5F7"
          padding="12px"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
          borderRadius="10px"
          marginTop="30px"
        >
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Price
            </Text>
            <Text fontWeight={600} fontSize="16px" lineHeight="24px">
              ~ {formatNumber(nftPriceCloud, 0)} CLOUD (${nftPriceUSD})
            </Text>
          </Flex>
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Collection
            </Text>
            <Text
              fontWeight={600}
              fontSize="16px"
              lineHeight="24px"
              color="#FFB524"
            >
              {collection}
            </Text>
          </Flex>
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Cloud Token
            </Text>
            <Text fontWeight={600} fontSize="16px" lineHeight="24px">
              $0.20
            </Text>
          </Flex>
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Total
            </Text>
            <Text fontWeight={600} fontSize="16px" lineHeight="24px">
              {formatNumber(total, 2)} CLOUD
            </Text>
          </Flex>
        </VStack>
      </CustomModal>

      <UsdtSaleModal
        title="Buy NFT"
        isOpen={isOpenUSDTModal}
        onOpen={onOpenUSDTModal}
        onClose={onCloseUSDTModal}
        disableAction={
          totalUSDT > balanceUSDT.div(1e18).toNumber() || quantity <= 0
        }
        quantity={quantity}
        nftType={nftType}
        allowance={allowanceUSDT}
        total={totalUSDT}
      >
        <Text
          marginBottom="10px"
          fontWeight={500}
          fontSize="14px"
          lineHeight="21px"
          float="right"
        >
          Your Balance: {getFullDisplayBalance(balanceUSDT, 18, 2)} USDT
        </Text>

        <Flex w="100%" justifyContent="space-between" marginBottom="10px">
          <Text
            fontWeight={500}
            fontSize="16px"
            lineHeight="24px"
            style={{ alignItems: "center", display: "flex" }}
          >
            Rank
          </Text>
          <Flex gap={2}>
            <Button
              style={rank === "common" ? { background: "#FFB524" } : {}}
              padding={2}
              onClick={() => {
                setRank("common");

                if (collection === "Normal") {
                  onCloseUSDTModal();
                  onOpen();
                }
              }}
            >
              Common
            </Button>
            <Button
              style={rank === "rare" ? { background: "#FFB524" } : {}}
              padding={2}
              onClick={() => {
                setRank("rare");

                if (collection === "Normal") {
                  onCloseUSDTModal();
                  onOpen();
                }
              }}
            >
              Rare
            </Button>
            <Button
              style={rank === "legendary" ? { background: "#FFB524" } : {}}
              padding={2}
              onClick={() => {
                setRank("legendary");
              }}
            >
              Legendary
            </Button>
          </Flex>
        </Flex>

        <Flex w="100%" justifyContent="space-between" marginBottom="10px">
          <Text
            fontWeight={500}
            fontSize="16px"
            lineHeight="24px"
            style={{ alignItems: "center", display: "flex" }}
          >
            Quantity
          </Text>
          <Input
            marginLeft="30px"
            type="number"
            value={quantity}
            onChange={handleInputChange}
          />
          <Button marginLeft="10px" onClick={maxQuantityUsdt}>
            Max
          </Button>
        </Flex>

        <VStack
          border="1px solid #F3F5F7"
          padding="12px"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
          borderRadius="10px"
          marginTop="30px"
        >
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Price
            </Text>
            <Text fontWeight={600} fontSize="16px" lineHeight="24px">
              {formatNumber(nftPriceUSD, 0)} USDT
            </Text>
          </Flex>
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Collection
            </Text>
            <Text
              fontWeight={600}
              fontSize="16px"
              lineHeight="24px"
              color="#FFB524"
            >
              {collection}
            </Text>
          </Flex>
          <Flex w="100%" justifyContent="space-between">
            <Text fontWeight={500} fontSize="16px" lineHeight="24px">
              Total
            </Text>
            <Text fontWeight={600} fontSize="16px" lineHeight="24px">
              {formatNumber(totalUSDT, 0)} USDT
            </Text>
          </Flex>
        </VStack>
      </UsdtSaleModal>
    </>
  );
}
