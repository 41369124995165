import { FC } from "react";

import styles from "./style.module.css";

interface RecentItemProps {
  isSelected: boolean;
  image: string;
  onSelect: () => any;
}

const RecentItem: FC<RecentItemProps> = ({ isSelected, image, onSelect }) => (
  <div
    className={`${isSelected ? styles.borderGradient : ""}`}
    onClick={onSelect}
  >
    <div className={styles.gridContainer}>
      <img src={image} alt={`img`} className={styles.singleImg} />
    </div>
  </div>
);

export default RecentItem;
